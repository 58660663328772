import * as React from "react"
import { theme } from "../../theme"

const WrenchIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.6566 18.9895L13.5766 9.90948C14.5066 7.56948 14.0266 4.80948 12.1366 2.90948C9.83659 0.609483 6.25659 0.399483 3.70659 2.25948L7.54659 6.10948L6.12659 7.51948L2.29659 3.68948C0.436592 6.22948 0.646592 9.81948 2.94659 12.1095C4.80659 13.9695 7.51659 14.4595 9.83659 13.5895L18.9466 22.6995C19.3366 23.0895 19.9666 23.0895 20.3566 22.6995L22.6566 20.3995C23.0566 20.0195 23.0566 19.3895 22.6566 18.9895ZM19.6566 20.5895L10.1966 11.1295C9.58659 11.5795 8.90659 11.8495 8.19659 11.9495C6.83659 12.1495 5.40659 11.7395 4.36659 10.6995C3.41659 9.75948 2.97659 8.49948 3.04659 7.25948L6.13659 10.3495L10.3766 6.10948L7.28659 3.01948C8.52659 2.94948 9.77659 3.38948 10.7266 4.32948C11.8066 5.40948 12.2166 6.89948 11.9666 8.28948C11.8466 8.99948 11.5466 9.65948 11.0866 10.2495L20.5366 19.6995L19.6566 20.5895Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}

export default WrenchIcon
