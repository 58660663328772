import * as React from "react"
import { AnimatePresence, motion } from "framer-motion"

export const SlideOutHeight: React.FunctionComponent<{
  children: React.ReactNode
  delay?: number
  isVisible: boolean
}> = ({ children, delay, isVisible }) => {
  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={{
            open: { height: "auto", opacity: 1 },
            closed: { height: 0, opacity: 0 },
          }}
          transition={{ duration: 0.5, ease: "easeInOut", delay: delay ?? 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
