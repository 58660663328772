import styled from "styled-components"

const H2 = styled.div<{ textAlign?: string; color?: string }>`
  color: ${(props) => props.color ?? props.theme.colors.greyDark};

  font-family: "Inter", "Inter-fallback", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;

  text-align: ${(props) => props.textAlign ?? "left"};
`

export default H2
