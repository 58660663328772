import React from "react"
import styled from "styled-components"

interface TooltipProps extends React.PropsWithChildren {
  backgroundColor?: string
  borderRadius?: string
  tooltipPosition?: TooltipPosition
  unClickable?: boolean
  toolTipPadding?: string
}

export interface TooltipPosition {
  transform: string
  left: string
  paddingTop: string
}

export const TooltipItem = styled.div<{
  maxWidth?: string
  noHover?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "none")};
  &:hover {
    filter: ${(props) => (props.noHover ? undefined : "brightness(60%)")};
  }
`

export const TooltipContainer = styled.div`
  position: relative;
  display: grid;

  .tooltip-wrapper {
    display: none;
  }

  &:hover > .tooltip-wrapper {
    display: block;
  }
`

const TooltipWrapper = styled.div<{
  position?: TooltipPosition
  unClickable?: boolean
}>`
  pointer-events: ${(props) => (props.unClickable ? "none" : undefined)};
  position: absolute;
  cursor: auto;
  transform: ${(props) => (props.position ? props.position.transform : "translateX(-50%)")};
  left: ${(props) => (props.position ? props.position.left : "50%")};
  z-index: 10;
  padding-top: ${(props) => (props.position ? props.position.paddingTop : "35px")};
`

const TooltipWithArrowAndBorder = styled.div<{
  background?: string
  borderRadius?: string
  padding?: string
}>`
  position: relative;
  width: max-content;
  background: ${(props) => (props.background ? props.background : props.theme.colors.white)};

  padding: ${(props) =>
    props.padding
      ? props.padding
      : `${props.theme.spacings.large} ${props.theme.spacings.extreme} ${props.theme.spacings.large} ${props.theme.spacings.extreme}`};
  border: ${(props) => `${props.theme.borders.smallWidth} solid ${props.theme.colors.greyDark}`};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : props.theme.borders.buttonRadius)};

  display: flex;
  align-items: start;
  flex-direction: column;
  list-style-type: none;
`

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  backgroundColor,
  borderRadius,
  tooltipPosition,
  unClickable,
  toolTipPadding,
}) => {
  return (
    <TooltipWrapper className="tooltip-wrapper" position={tooltipPosition} unClickable={unClickable}>
      <TooltipWithArrowAndBorder background={backgroundColor} borderRadius={borderRadius} padding={toolTipPadding}>
        {children}
      </TooltipWithArrowAndBorder>
    </TooltipWrapper>
  )
}

export default Tooltip
