import * as React from "react"
import ChevronsIcon from "../../atoms/icons/ChevronsIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import FileUploadIcon from "../../atoms/icons/FileUploadIcon"
import TextButton from "../../atoms/buttons/TextButton"
import ExternalLinkIcon from "../../atoms/icons/ExternalLinkIcon"
import DownloadingIcon from "../../atoms/icons/DownloadingIcon"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import SoftwareUpdateModal from "../modals/SoftwareUpdateModal"
import SoftwareUpdateProgressModal from "../modals/SoftwareUpdateProgressModal"
import { isAvailableUpdateNewer } from "../../../utils/version-helpers"
import { pages } from "../../../utils/pages"

interface SoftwareCardProps {
  osVersion?: string
  latestOsVersionAvailable?: string
  isCheckingForUpdates?: boolean
  installOsUpdateModalOpen?: boolean
  useLocalUpdate?: boolean
  setIsCheckingForUpdates: (value: boolean) => void
  onSoftwareUpdateClick: () => void
  setInstallOsUpdateModalOpen: (value: boolean) => void
  setUseLocalUpdate: (value: boolean) => void
}

const SOFTWARE_TOOLTIP = `
Note: “Check for updates” will automatically search for new updates while connected to the internet, and notify if there are any.
Searching for and downloading updates will consume data.
A typical update is between 10 and 100MB.
Software updates will only be installed after being initiated by a user.
`

const SoftwareCard: React.FunctionComponent<SoftwareCardProps> = ({
  osVersion,
  latestOsVersionAvailable,
  isCheckingForUpdates,
  setIsCheckingForUpdates,
  installOsUpdateModalOpen,
  useLocalUpdate,
  onSoftwareUpdateClick,
  setUseLocalUpdate,
  setInstallOsUpdateModalOpen,
}) => {
  const [initiateUpdate, setInitiateUpdate] = React.useState(false)

  const handleLocalSoftwareUpdateClick = () => {
    setUseLocalUpdate(true)
    setInstallOsUpdateModalOpen(true)
    // setInitiateUpdate(true)
  }

  const handleSoftwareUpdateConfirm = () => {
    setInstallOsUpdateModalOpen(false)
    setInitiateUpdate(true)
  }

  const handleToggleUpdates = () => {
    setIsCheckingForUpdates(!isCheckingForUpdates)
  }

  return (
    <>
      <SettingsOverviewCard title={pages.system.sections[1].name} icon={<ChevronsIcon />} tooltip={SOFTWARE_TOOLTIP}>
        <ReadOnlyTextField label="Software version" value={osVersion} placeholder="-.-.-" />
        <ButtonRow
          label="Check for updates"
          button={<ToggleButton active={isCheckingForUpdates} onToggle={handleToggleUpdates} />}
        />
        <ButtonRow
          label="Local update"
          button={
            <PrimaryButton
              size="small"
              variant="light"
              onClick={handleLocalSoftwareUpdateClick}
              iconLeft={<FileUploadIcon />}
              title="Browse"
            />
          }
        />
        <ButtonRow
          label="Open source licenses"
          button={<TextButton icon={<ExternalLinkIcon />} title="Software list" />}
        />
        {latestOsVersionAvailable && osVersion && isAvailableUpdateNewer(latestOsVersionAvailable, osVersion) ? (
          <ButtonRow
            label={`An OS update (version ${latestOsVersionAvailable}) is available`}
            button={
              <PrimaryButton onClick={onSoftwareUpdateClick} iconLeft={<DownloadingIcon />} title="Install update" />
            }
          />
        ) : (
          <ReadOnlyTextField label="You're all up to date!" value="" />
        )}
      </SettingsOverviewCard>
      {installOsUpdateModalOpen && (
        <SoftwareUpdateModal
          open={installOsUpdateModalOpen}
          onClose={() => setInstallOsUpdateModalOpen(false)}
          onConfirm={handleSoftwareUpdateConfirm}
          version={latestOsVersionAvailable}
          isLocalUpdate={useLocalUpdate}
        />
      )}
      {!installOsUpdateModalOpen && initiateUpdate && <SoftwareUpdateProgressModal />}
    </>
  )
}

export default SoftwareCard
