import * as React from "react"

const SpinnerIcon: React.FunctionComponent = () => {
  return (
    <svg
      style={{ animation: "spin 0.7s linear infinite" }}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387986 16.9698 -8.61039e-08 15 0L15 4.67725C16.3556 4.67725 17.6979 4.94426 18.9503 5.46303C20.2028 5.98179 21.3407 6.74216 22.2993 7.70072C23.2578 8.65927 24.0182 9.79724 24.537 11.0497C25.0557 12.3021 25.3227 13.6444 25.3227 15H30Z"
        fill="url(#paint0_linear_1103_77923)"
      />
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
      <defs>
        <linearGradient
          id="paint0_linear_1103_77923"
          x1="1.71806"
          y1="5.45454"
          x2="27.4775"
          y2="8.65342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81A6C8" />
          <stop offset="1" stopColor="#97D1D9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SpinnerIcon
