import { theme } from "../components/theme"

export type ColorVariant = "light" | "dark" | "blue" | "yellow" | "red" | "green"

export const isColorfulVariant = (variant: ColorVariant | undefined) =>
  variant && variant !== "light" && variant !== "dark"

export const getVariantTextColor = (variant: ColorVariant | undefined): string => {
  switch (variant) {
    default:
    case "dark":
    case "blue":
    case "green":
    case "red":
      return theme.colors.white
    case "yellow":
    case "light":
      return theme.colors.greyDark
  }
}

export const getVariantColor = (variant: ColorVariant | undefined): string => {
  switch (variant) {
    default:
    case "dark":
      return theme.colors.greyDark
    case "light":
      return theme.colors.white
    case "blue":
      return theme.gradients.blue
    case "green":
      return theme.gradients.green
    case "red":
      return theme.gradients.red
    case "yellow":
      return theme.gradients.yellow
  }
}

export const getVariantHoverColor = (variant: ColorVariant | undefined): string => {
  switch (variant) {
    default:
    case "dark":
      return theme.colors.greyDarker
    case "light":
      return theme.colors.background
    case "blue":
      return theme.gradients.blueBorder
    case "green":
      return theme.gradients.greenBorder
    case "red":
      return theme.gradients.redBorder
    case "yellow":
      return theme.gradients.yellowBorder
  }
}

export const getVariantBorderColor = (variant: ColorVariant | undefined): string => {
  switch (variant) {
    default:
    case "dark":
      return theme.colors.greyDark
    case "light":
      return theme.colors.greyDarker
    case "blue":
      return theme.gradients.blueBorder
    case "green":
      return theme.gradients.greenBorder
    case "red":
      return theme.gradients.redBorder
    case "yellow":
      return theme.gradients.yellowBorder
  }
}
