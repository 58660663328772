import * as React from "react"

interface IconProps {
  color?: string
  opacity?: string
}

// eslint-disable-next-line
export const CheckboxActive: React.FunctionComponent<IconProps> = ({ color = "#616161", opacity = "1.0" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="21" height="21" rx="1.5" fill="white" stroke={color} strokeWidth="3" />
    <rect x="6.26086" y="6.26086" width="11.4783" height="11.4783" rx="1" fill={color} />
  </svg>
)

// eslint-disable-next-line
export const CheckboxPassive: React.FunctionComponent<IconProps> = ({ color = "#616161", opacity = "1.0" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="21" height="21" rx="1.5" fill="white" stroke={color} strokeWidth="3" />
  </svg>
)

export const CheckmarkIcon: React.FunctionComponent<IconProps> = ({ color = "#616161" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.69999L19.4 5.29999L8.79995 15.9Z"
      fill={color}
    />
  </svg>
)
