import * as React from "react"
import { Col, Row } from "react-grid-system"
import { HorizontalSpacer } from "../atoms/spacers"
import ContactInformationCard from "../organisms/support/ContactInformationCard"
import GuidesAndManualsCard from "../organisms/support/GuidesAndManualsCard"
import { ErrorMessage } from "../../types"
import PageContainer from "../atoms/PageContainer"

export const ERROR_CODE_INDEX_TOOLTIP = `
This section contains a list of known errors, along with descriptions of the causes and fixes. Use it to troubleshoot, or when in contact with support in case of problems with your terminal.
`

const GUIDES_AND_MANUALS_FILES = [
  {
    fileName: "Satcube Motion introduction",
    url: "https://images.app.goo.gl/7E9FpH5iSpxwd2gVA",
  },
  {
    fileName: "Satcube Motion WebGUI introduction",
    url: "https://images.app.goo.gl/FBbuQJ5oywHrnPuc8",
  },
  {
    fileName: "Satcube broadband 101",
    url: "https://images.app.goo.gl/Gf1u3U3AgdzPxcAz5",
  },
  {
    fileName: "Satcube Motion manual",
    url: "https://images.app.goo.gl/vXvxwaLbjKmadHgm8",
  },
  {
    fileName: "Get started with the Satcube app",
    url: "https://images.app.goo.gl/iacFuZUfUtnsaMRz9",
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ERROR_CODE_INDEX_ERRORS: ErrorMessage[] = [
  {
    title: "#0345 - BUC overheated",
    message: "Error description / message",
    severity: "error",
    solution: "Proposed solution",
  },
  {
    title: "#0346 - LNB overheated",
    message: "Error description / message",
    severity: "error",
    solution: "Proposed solution",
  },
  {
    title: "#0347 - Modem connection lost",
    message: "Error description / message",
    severity: "error",
    solution: "Proposed solution",
  },
  {
    title: "#0350 - Servo error",
    message: "Error description / message",
    severity: "error",
    solution: "Proposed solution",
  },
  {
    title: "#0352 - Battery depletion",
    message: "Error description / message",
    severity: "error",
    solution: "Proposed solution",
  },
]

const SupportPage: React.FunctionComponent = () => {
  return (
    <PageContainer>
      <HorizontalSpacer spacing={4} />
      <Row>
        <Col>
          <div id="contact">
            <ContactInformationCard />
          </div>
          <HorizontalSpacer spacing={4} />
          <div id="guides-and-manuals">
            <GuidesAndManualsCard files={GUIDES_AND_MANUALS_FILES} />
          </div>
          {/* TODO unhide after mvp */}
          {/* <HorizontalSpacer spacing={4} />
          <div id="error-codes">
            <ErrorCodeIndexCard errors={ERROR_CODE_INDEX_ERRORS} />
          </div> */}
          <HorizontalSpacer spacing={4} />
        </Col>
      </Row>
    </PageContainer>
  )
}

export default SupportPage
