import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import TxIcon24x24 from "../../atoms/icons/TxIcon24x24"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import TurnOffTxModal from "../modals/TurnTxOffModal"

const TransmissionCard: React.FunctionComponent = () => {
  const [transmission, setTransmission] = React.useState<boolean>(/*true*/)
  const [txConfirmationModalOpen, setTxConfirmationModalOpen] = React.useState<boolean>(/*false*/)

  const handleToggleTransmission = () => {
    if (transmission) {
      setTxConfirmationModalOpen(true)
    } else {
      setTransmission(true)
    }
  }

  const handleConfirmTransmissionOff = () => {
    setTransmission(false)
    setTxConfirmationModalOpen(false)
  }

  return (
    <>
      <SettingsOverviewCard title="Transmission" icon={<TxIcon24x24 />}>
        <ButtonRow
          label="Transmission"
          button={<ToggleButton active={transmission} onToggle={handleToggleTransmission} />}
        />
      </SettingsOverviewCard>
      {txConfirmationModalOpen && (
        <TurnOffTxModal
          open={txConfirmationModalOpen}
          onClose={() => setTxConfirmationModalOpen(false)}
          onConfirm={handleConfirmTransmissionOff}
        />
      )}
    </>
  )
}

export default TransmissionCard
