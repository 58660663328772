const HTTP_SCHEME = "https"
const WS_SCHEME = "wss"
const API_PORT = ""

export const EDGE_HTTP_API = `${HTTP_SCHEME}://${window.location.hostname}${API_PORT}/api`
export const EDGE_WS_API = `${WS_SCHEME}://${window.location.hostname}${API_PORT}/api/ws`
export const EDGE_WS_METRICS = `${EDGE_WS_API}/metrics?follow=true`
export const EDGE_WS_EVENTS = `${EDGE_WS_API}/events`
export const EDGE_HTTP_METADATA = `${EDGE_HTTP_API}/metadata`
export const EDGE_HTTP_LOGIN = `${EDGE_HTTP_API}/login`
export const EDGE_HTTP_LOGOUT = `${EDGE_HTTP_API}/logout`
