import * as React from "react"
import ModemIcon from "../../atoms/icons/ModemIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import EditablePasswordField from "../../molecules/inputs/EditableSettingsSecretField"
import QuestionMarkIcon from "../../atoms/icons/QuestionMarkIcon"
import Tooltip, { TooltipContainer, TooltipItem, TooltipPosition } from "../../molecules/Tooltip"
import { theme } from "../../theme"
import BodyText from "../../atoms/typography/BodyText"
import ButtonRow from "../../molecules/card/ButtonRow"
import ExternalLinkIcon from "../../atoms/icons/ExternalLinkIcon"
import { pages } from "../../../utils/pages"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"

const modemPasswordTooltipPosition: TooltipPosition = {
  transform: "translateX(-52%)",
  left: "80%",
  paddingTop: "2.25rem",
}

const ModemCard: React.FunctionComponent = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [modemModel, setModemModel] = React.useState(/*"iDirect IQ200"*/)
  const [modemSerialNumber, setModemSerialNumber] = React.useState(/*"12345"*/)
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const [modemPassword, setModemPassword] = React.useState<string>(/*"admin"*/)

  return (
    <SettingsOverviewCard title={pages.system.sections[2].name} icon={<ModemIcon />}>
      <ReadOnlyTextField label="Model" value={modemModel} placeholder="------" />
      <ReadOnlyTextField label="Serial number" value={modemSerialNumber} placeholder="------" />
      <EditablePasswordField
        label="Password"
        value={modemPassword}
        onChange={setModemPassword}
        labelIcon={
          <TooltipContainer>
            <QuestionMarkIcon />
            <Tooltip
              backgroundColor={theme.colors.greyDark}
              borderRadius="12px"
              tooltipPosition={modemPasswordTooltipPosition}
              unClickable={true}
              toolTipPadding="1rem"
            >
              <TooltipItem maxWidth="250px" noHover={true}>
                <BodyText color="white">
                  Note: This password is used by the terminal to access the modem. Changing this password does not
                  change the password in the modem.
                </BodyText>
              </TooltipItem>
            </Tooltip>
          </TooltipContainer>
        }
      />
      <ButtonRow
        label="Modem configuration (external)"
        button={<PrimaryButton size="small" variant="light" title="Go to modem page" iconLeft={<ExternalLinkIcon />} />}
      />
    </SettingsOverviewCard>
  )
}

export default ModemCard
