import styled from "styled-components"

const TextArea = styled.textarea`
  font-size: 16px;
  font-family: "Karla", sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.greyDark};
  padding: 8px 8px 8px 16px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.greyDisabled};
  background: ${(props) => props.theme.colors.buttonLight};
  box-shadow: 0px 0px 4px 0px rgba(119, 119, 119, 0.25) inset;
  min-height: 10ch;
  box-sizing: border-box;
`

export default TextArea
