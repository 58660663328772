import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  color?: string
}

const DownloadingIcon: React.FunctionComponent<IconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.3454 4.26005C16.8654 3.05005 15.0354 2.25005 13.0254 2.05005V4.07005C14.4854 4.25005 15.8154 4.83005 16.9254 5.69005L18.3454 4.26005ZM19.9554 11H21.9754C21.7754 8.99005 20.9754 7.16005 19.7654 5.68005L18.3354 7.10005C19.1954 8.21005 19.7754 9.54005 19.9554 11ZM18.3354 16.9L19.7654 18.33C20.9754 16.85 21.7754 15.01 21.9754 13.01H19.9554C19.7754 14.46 19.1954 15.79 18.3354 16.9ZM13.0254 19.93V21.9501C15.0354 21.75 16.8654 20.9501 18.3454 19.74L16.9154 18.31C15.8154 19.17 14.4854 19.75 13.0254 19.93ZM15.6154 10.59L13.0254 13.17V7.00005H11.0254V13.17L8.43539 10.58L7.02539 12L12.0254 17L17.0254 12L15.6154 10.59ZM11.0254 19.93V21.9501C5.97539 21.4501 2.02539 17.19 2.02539 12C2.02539 6.81005 5.97539 2.55005 11.0254 2.05005V4.07005C7.07539 4.56005 4.02539 7.92005 4.02539 12C4.02539 16.08 7.07539 19.4401 11.0254 19.93Z"
        fill={color ?? theme.colors.white}
      />
    </svg>
  )
}

export default DownloadingIcon
