import * as React from "react"
import { theme } from "../../theme"

const SatelliteIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1661_2768)">
        <path
          d="M21.5021 14.5013H23.5021C23.5021 19.4713 19.4721 23.5013 14.5021 23.5013V21.5013C18.3721 21.5013 21.5021 18.3713 21.5021 14.5013ZM14.5021 17.5013V19.5013C17.2621 19.5013 19.5021 17.2613 19.5021 14.5013H17.5021C17.5021 16.1613 16.1621 17.5013 14.5021 17.5013ZM18.7621 1.09128L22.3021 4.63128C23.0821 5.41128 23.0821 6.68128 22.3021 7.46128L19.1221 10.6413C18.3421 11.4213 17.0721 11.4213 16.2921 10.6413L15.0521 9.40128L14.3421 10.1013L15.5821 11.3413C16.3621 12.1213 16.3621 13.3913 15.5821 14.1713L14.1721 15.5813C13.3921 16.3613 12.1221 16.3613 11.3421 15.5813L10.1021 14.3413L9.39207 15.0513L10.6321 16.2913C11.4121 17.0713 11.4121 18.3413 10.6321 19.1213L7.45207 22.3013C6.67207 23.0813 5.40207 23.0813 4.62207 22.3013L1.08207 18.7613C0.30207 17.9813 0.30207 16.7113 1.08207 15.9313L4.26207 12.7513C5.04207 11.9713 6.31207 11.9713 7.09207 12.7513L8.33207 13.9913L9.04207 13.2813L7.80207 12.0513C7.02207 11.2713 7.02207 10.0013 7.80207 9.22128L9.22207 7.80128C10.0021 7.02128 11.2721 7.02128 12.0521 7.80128L13.2921 9.04128L14.0021 8.33128L12.7521 7.10128C11.9721 6.32128 11.9721 5.05128 12.7521 4.27128L15.9321 1.09128C16.7221 0.301279 17.9821 0.301279 18.7621 1.09128ZM3.56207 16.2913L2.50207 17.3513L6.04207 20.8913L7.10207 19.8313L3.56207 16.2913ZM5.68207 14.1713L4.62207 15.2313L8.16207 18.7713L9.22207 17.7113L5.68207 14.1713ZM10.6321 9.22128L9.22207 10.6313L12.7621 14.1713L14.1721 12.7613L10.6321 9.22128ZM15.2321 4.62128L14.1721 5.68128L17.7121 9.22128L18.7721 8.16128L15.2321 4.62128ZM17.3521 2.50128L16.2921 3.56128L19.8321 7.10128L20.8921 6.04128L17.3521 2.50128Z"
          fill={theme.colors.white}
        />
      </g>
      <defs>
        <clipPath id="clip0_1661_2768">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SatelliteIcon
