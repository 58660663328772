import styled from "styled-components"

const ButtonTextSmall = styled.div<{ textAlign?: string; color?: string; opacity?: string }>`
  color: ${(props) => props.color ?? props.theme.colors.greyDark};
  opacity: ${(props) => props.opacity ?? "1"};

  font-family: "Inter", "Inter-fallback", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;

  text-align: ${(props) => props.textAlign ?? "left"};
`

export default ButtonTextSmall
