import React from "react"
import { theme } from "../theme"
import { Container } from "react-grid-system"

const PageContainer: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  return (
    <Container data-testid="page-container" style={{ maxWidth: theme.containers.small }}>
      {children}
    </Container>
  )
}

export default PageContainer
