import * as React from "react"
import { theme } from "../../theme"

const ChangeCircleIcon: React.FunctionComponent = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="change_circle">
        <path
          id="Vector"
          d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM12.5 20C8.09 20 4.5 16.41 4.5 12C4.5 7.59 8.09 4 12.5 4C16.91 4 20.5 7.59 20.5 12C20.5 16.41 16.91 20 12.5 20ZM16.67 14.76L15.57 13.66C16.28 12.33 16.1 10.65 14.98 9.53C14.29 8.84 13.4 8.5 12.5 8.5C12.47 8.5 12.44 8.51 12.41 8.51L13.5 9.6L12.44 10.66L9.61 7.83L12.44 5L13.5 6.06L12.54 7.02C13.81 7.03 15.07 7.5 16.04 8.46C17.74 10.17 17.95 12.82 16.67 14.76ZM15.39 16.17L12.56 19L11.5 17.94L12.45 16.99C11.19 16.98 9.93 16.49 8.97 15.53C7.26 13.82 7.05 11.18 8.33 9.24L9.43 10.34C8.72 11.67 8.9 13.35 10.02 14.47C10.72 15.17 11.65 15.51 12.58 15.48L11.5 14.4L12.56 13.34L15.39 16.17Z"
          fill={theme.colors.white}
        />
      </g>
    </svg>
  )
}

export default ChangeCircleIcon
