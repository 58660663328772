import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import H2 from "../../atoms/typography/H2"
import H3 from "../../atoms/typography/H3"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"
import BodyText from "../../atoms/typography/BodyText"
import InputBox from "../../molecules/inputs/InputBox"
import TextArea from "../../atoms/inputs/TextArea"

interface DiagnosticPackageModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const DiagnosticPackageModal: React.FunctionComponent<DiagnosticPackageModalProps> = ({ open, onClose, onConfirm }) => {
  const [inputName, setInputName] = React.useState("")
  const [inputContactInfo, setInputContactInfo] = React.useState("")
  const [inputMessage, setInputMessage] = React.useState("")

  const handleInputNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputName(event.target.value)
  }

  const handleInputContactInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputContactInfo(event.target.value)
  }

  const handleInputMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputMessage(event.target.value)
  }

  return (
    <AnimatePresence mode="wait">
      {open && (
        <Modal bigTitle title="Satcube diagnostic package" onClose={onClose} closeIcon>
          <H2>Get the help you need in three simple steps.</H2>
          <HorizontalSpacer spacing={1} />
          <Row>
            <Col>
              <ol>
                <H3 as="li">Describe your issue below</H3>
                <H3 as="li">Download the diagnostic package</H3>
                <H3 as="li">
                  Send it to support@satcube.com once you have re-established an internet connection. We’ll get back to
                  you as soon as possible.
                </H3>
              </ol>
            </Col>
          </Row>

          <SettingsFieldWithLabel label="Name">
            <InputBox value={inputName} onChange={handleInputNameChange} placeholder="Name" />
          </SettingsFieldWithLabel>
          <SettingsFieldWithLabel label="Contact information">
            <InputBox value={inputContactInfo} onChange={handleInputContactInfoChange} placeholder="Email" />
          </SettingsFieldWithLabel>

          <HorizontalSpacer spacing={2} />
          <BodyText>Message</BodyText>
          <HorizontalSpacer spacing={1} />
          <Col>
            <Row direction="column" align="stretch">
              <TextArea
                value={inputMessage}
                onChange={handleInputMessageChange}
                placeholder="Type your message here..."
              />
            </Row>
          </Col>
          <HorizontalSpacer spacing={2} />

          <Row align="center" justify="end">
            <Col width={"fit-content"}>
              <PrimaryButton title="Download Diagnostic Package" onClick={onConfirm} />
            </Col>
          </Row>
        </Modal>
      )}
    </AnimatePresence>
  )
}

export default DiagnosticPackageModal
