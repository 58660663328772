import { Middleware, Action, PayloadAction } from "@reduxjs/toolkit"
import { CheckAuth, checkAuth, clearUser } from "./userSlice"

export const sessionExpirationMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const typedAction = action as Action<string>
    const state = getState()
    const { sessionExpires } = state.user

    if (typedAction.type === checkAuth.type && sessionExpires && Date.now() > sessionExpires) {
      const checkAuth = action as PayloadAction<CheckAuth>
      if (checkAuth.payload.appStart) {
        dispatch(clearUser("session_expired_app_start"))
      } else {
        dispatch(clearUser("session_expired"))
      }
    }

    return next(action)
  }
