import { motion } from "framer-motion"
import * as React from "react"
import styled from "styled-components"

interface BackdropProps {
  children: React.ReactNode
  onClick: () => void
  backdropColor: string
}

const BackdropStyle = styled(motion.div)<{ backdropColor: string }>`
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) => props.backdropColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
`

const Backdrop: React.FunctionComponent<BackdropProps> = ({ children, onClick, backdropColor }) => {
  return (
    <BackdropStyle
      data-testid="backdrop"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      backdropColor={backdropColor}
    >
      {children}
    </BackdropStyle>
  )
}

export default Backdrop
