import { DeviceMetadataMessage } from "edge-proto/dist/edge/v1/device_metadata"
import * as protoMetricsMetadata from "edge-proto/dist/edge/v1/metrics_metadata"
import { MissingMetadataError, ProtoMissingFieldError } from "./Errors"

export interface MetricGroupMetadata {
  id: string
  values: MetricValueMetadata[]
  value(id: string): MetricValueMetadata | undefined
}

// Immutable interface containg the metadata about the device
export interface DeviceMetadata {
  metricGroup(groupId: string): MetricGroupMetadata | MissingMetadataError
}

// A validator that checks that the string value of an metric value
// is one of the defined enums.
export type EnumMetricValidator = {
  isValid(value: string): boolean
  expectedValues: string[]
} & { $case: "enum" }

export type NoMetricValidator = { $case: "noValidator" }

export type MetricValidator = EnumMetricValidator | NoMetricValidator

export interface MetricValueMetadata {
  id: string
  fullId: string
  index: number
  validator: MetricValidator
}

function metricValidator(metric: protoMetricsMetadata.MetricMetadata): MetricValidator {
  const metricType = metric.type

  if (!metricType) {
    return { $case: "noValidator" }
  }

  switch (metricType.$case) {
    case "choices":
      return {
        $case: "enum",
        isValid(value: string): boolean {
          return metricType.choices.choices.includes(value)
        },
        expectedValues: metricType.choices.choices,
      }
    default:
      return {
        $case: "noValidator",
      }
  }
}

function fromProtoMetricValueMetadata(
  metricGroupId: string,
  metric: protoMetricsMetadata.MetricMetadata,
  index: number,
): MetricValueMetadata {
  return { ...metric, ...{ fullId: `${metricGroupId}.${metric}`, index, validator: metricValidator(metric) } }
}

export function parseMetadata(message: DeviceMetadataMessage): DeviceMetadata | ProtoMissingFieldError {
  const metadata = message.metadata
  if (metadata === undefined) {
    return new ProtoMissingFieldError("DeviceMetadataMessage", "Metadata")
  }

  const metricsMetadata = metadata.metricsMetadata
  if (metricsMetadata === undefined) {
    return new ProtoMissingFieldError("DeviceMetadataMessage.Metadata", "MetricsMetadata")
  }

  const idToMetricGroup: { [key: string]: protoMetricsMetadata.MetricGroupMetadata } =
    metricsMetadata.metricGroups.reduce((obj, metricGroup) => {
      return { ...obj, ...{ [metricGroup.id]: metricGroup } }
    }, {})

  return {
    metricGroup(groupId: string): MetricGroupMetadata | MissingMetadataError {
      const metricGroup = idToMetricGroup[groupId]
      if (metricGroup === undefined) {
        return new MissingMetadataError(`Metric group ${groupId} is missing from metadata`)
      }
      const values = metricGroup.metrics.map((metric, index) =>
        fromProtoMetricValueMetadata(metricGroup.id, metric, index),
      )
      const idToValue: { [key: string]: MetricValueMetadata } = values.reduce(
        (obj, metric) => ({ ...obj, ...{ [metric.id]: metric } }),
        {},
      )
      return {
        id: groupId,
        values: values,
        value(id: string): MetricValueMetadata | undefined {
          return idToValue[id]
        },
      }
    },
  }
}
