import * as React from "react"
import { Row, Col } from "react-grid-system"
import { useEdgeApi } from "../../edge/EdgeProvider"
import { AntennaOrientation, PolarizationOrientation } from "../../types"
import { HorizontalSpacer } from "../atoms/spacers"
import SatelliteSignalCard from "../organisms/overview/SatelliteSignalCard"
import SystemMessagesCard from "../organisms/overview/SystemMessagesCard"
import WifiCard from "../organisms/overview/WifiCard"
import PositionCard from "../organisms/overview/PositionCard"
import TransmissionCard from "../organisms/overview/TransmissionCard"
import PageContainer from "../atoms/PageContainer"
import { Metrics } from "../../edge/Metrics"
import { DeepPartial } from "edge-proto/dist/edge/v1/metrics"

const ORIENTATION_FLOAT_DECIMAL_COUNT = 1

const antennaOrientation = (metrics: Metrics, metricGroup: string): DeepPartial<AntennaOrientation> => {
  return {
    polarization: {
      value: metrics.value(metricGroup, "polarization")?.toRoundedFloat(ORIENTATION_FLOAT_DECIMAL_COUNT),
      orientation: metrics.value(metricGroup, "polarization_orientation")?.toEnum<PolarizationOrientation>(),
    },
    azimuth: metrics.value(metricGroup, "azimuth")?.toRoundedFloat(ORIENTATION_FLOAT_DECIMAL_COUNT),
    elevation: metrics.value(metricGroup, "elevation")?.toRoundedFloat(ORIENTATION_FLOAT_DECIMAL_COUNT),
  }
}

const HomePage: React.FunctionComponent<{ headerHeight: number }> = () => {
  const { metrics } = useEdgeApi()
  return (
    <>
      <PageContainer>
        <HorizontalSpacer spacing={4} />
        <Row>
          <Col>
            <SatelliteSignalCard
              currentOrientation={antennaOrientation(metrics, "current_orientation")}
              targetOrientation={antennaOrientation(metrics, "target_orientation")}
            />
            <HorizontalSpacer spacing={2} />
            <SystemMessagesCard />
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <PositionCard />
            <HorizontalSpacer spacing={2} />
          </Col>
          <Col>
            <WifiCard />
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <TransmissionCard />
          </Col>
        </Row>
        <HorizontalSpacer spacing={4} />
      </PageContainer>
    </>
  )
}

export default HomePage
