import * as React from "react"
import styled from "styled-components"
import { theme } from "../theme"
import H2 from "../atoms/typography/H2"
import BodyTextLarge from "../atoms/typography/BodyTextLarge"
import PrimaryButton from "../atoms/buttons/PrimaryButton"
import { Spacer } from "../atoms/spacers"
import ChevronsIcon from "../atoms/icons/ChevronsIcon"
import IconContainer from "../atoms/icons/IconContainer"

interface SoftwareDownloadBannerProps {
  version: string
  onDismiss: () => void
  onLearnMore: () => void
  headerHeight: number
}

const SoftwareDownloadBannerContainer = styled.div<{ headerHeight: number }>`
  top: ${(props) => props.headerHeight}px;
  position: sticky;
  z-index: 1;
  border-bottom: 4px solid ${theme.colors.graphColorBlue};
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(119, 119, 119, 0.25);
  padding: 12px 32px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 24px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`

const VersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const SoftwareDownloadBanner: React.FunctionComponent<SoftwareDownloadBannerProps> = ({
  version,
  onDismiss,
  onLearnMore,
  headerHeight,
}) => {
  return (
    <SoftwareDownloadBannerContainer headerHeight={headerHeight}>
      <IconContainer>
        <ChevronsIcon />
      </IconContainer>
      <Spacer spacing={1} />
      <VersionContainer>
        <H2>Software update available</H2>
        <BodyTextLarge>Version {version} is available for download</BodyTextLarge>
      </VersionContainer>
      <ButtonsContainer>
        <PrimaryButton variant="light" title="Dismiss" onClick={onDismiss} />
        <PrimaryButton title="Learn more" onClick={onLearnMore} />
      </ButtonsContainer>
    </SoftwareDownloadBannerContainer>
  )
}

export default SoftwareDownloadBanner
