import * as React from "react"
import styled from "styled-components"
import { MediaQuery } from "../../../utils/design-helpers"
import { CrossIcon } from "../../atoms/icons/CrossIcon"
import { motion } from "framer-motion"
import Backdrop from "../../atoms/Backdrop"
import H2 from "../../atoms/typography/H2"
import H1 from "../../atoms/typography/H1"

interface ModalProps {
  children: React.ReactNode
  onClose: () => void
  title: string
  bigTitle?: boolean
  closeIcon?: boolean
  backdropColor?: string
  fixedWidth?: boolean
  centerTitle?: boolean
  leftIcon?: React.ReactNode
}

const CrossIconButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;

  :hover {
    filter: brightness(40%);
  }
`

const TitleSection = styled.div<{ centerTitle: boolean; leftIcon: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.centerTitle && props.leftIcon ? "auto auto" : "auto 1fr auto")};
  justify-content: ${(props) => (props.centerTitle ? "center" : "start")};
  align-items: center;
  column-gap: 1rem;
  width: 100%;
`

const ModalStyle = styled(motion.div)<{ fixedWidth?: boolean }>`
  background-color: ${(props) => props.theme.colors.white};
  margin: 15% auto;
  padding: ${(props) => props.theme.spacings.extreme};
  border: 1px solid black;
  border-radius: ${(props) => props.theme.borders.largeRadius};
  ${({ fixedWidth }) => fixedWidth && "width: 50%;"}

  max-height: 90vh;
  max-width: 750px;
  overflow-y: auto;

  ${MediaQuery.MD} {
    ${({ fixedWidth }) => fixedWidth && "width: 70%;"}
  }
  ${MediaQuery.XS} {
    ${({ fixedWidth }) => fixedWidth && "width: 85%;"}
    padding: ${(props) => props.theme.spacings.large};
  }
`

const dropIn = {
  hidden: {
    y: "-100%",
    opacity: 0,
  },
  visible: { y: 0, opacity: 1 },
  exit: {
    y: "100%",
    opacity: 0,
  },
}

const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  onClose,
  title,
  closeIcon,
  backdropColor,
  fixedWidth = true,
  leftIcon,
  bigTitle = false,
  centerTitle = false,
}) => {
  return (
    <>
      <Backdrop onClick={onClose} backdropColor={backdropColor ?? "#000000e1"}>
        <ModalStyle
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          fixedWidth={fixedWidth}
        >
          <TitleSection centerTitle={centerTitle} leftIcon={leftIcon !== undefined}>
            {leftIcon}
            {bigTitle ? <H1>{title}</H1> : <H2>{title}</H2>}
            {closeIcon && (
              <CrossIconButton onClick={onClose}>
                <CrossIcon />
              </CrossIconButton>
            )}
          </TitleSection>
          {children}
        </ModalStyle>
      </Backdrop>
    </>
  )
}

export default Modal
