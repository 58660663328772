import * as React from "react"
import { theme } from "../../theme"
import styled, { keyframes, css } from "styled-components"
import { TerminalStatusState } from "../../../types"

interface IconProps {
  status: TerminalStatusState
}

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const AnimatedCircle = styled.circle<{ animate: boolean }>`
  animation: ${(props) =>
    props.animate
      ? css`
          ${pulse} 1.2s ease-in infinite 1s
        `
      : "none"};
`

const StatusIcon: React.FC<IconProps> = ({ status }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <AnimatedCircle
        id="Ellipse 1"
        cx="6"
        cy="6.5"
        r="6"
        fill={
          status === "connected"
            ? theme.colors.crayonGreen
            : status === "error"
              ? theme.colors.crayonRed
              : theme.colors.greyLighter
        }
        animate={status === "loading"}
      />
    </svg>
  )
}

export default StatusIcon
