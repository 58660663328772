import * as React from "react"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"
import styled from "styled-components"
import { MediaQuery } from "../../../utils/design-helpers"

interface ButtonRowProps {
  label: string
  button: React.ReactNode
}

const ButtonContainer = styled.div`
  ${MediaQuery.XS} {
    padding-top: 1rem;
  }
`

const ButtonRow: React.FunctionComponent<ButtonRowProps> = ({ label, button }) => {
  return (
    <SettingsFieldWithLabel label={label}>
      <ButtonContainer>{button}</ButtonContainer>
    </SettingsFieldWithLabel>
  )
}

export default ButtonRow
