import * as React from "react"
import GenericConfirmationModal from "../../molecules/modals/GenericConfirmationModal"

interface TurnOffWifiModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const TurnOffWifiModal: React.FunctionComponent<TurnOffWifiModalProps> = ({ open, onClose, onConfirm }) => {
  return (
    <GenericConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={"Turn off Wifi"}
      message={`
      Disabling the Wifi will disconnect all users not connected to the terminal by cable. 
      Ensure that you have a wired connection before continuing.
      `}
      confirmText={"Turn off Wifi"}
    />
  )
}

export default TurnOffWifiModal
