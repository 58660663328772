import * as React from "react"
import { theme } from "../../theme"

const WifiIcon24x24: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M1 8.77626L3 10.7763C7.97 5.80626 16.03 5.80626 21 10.7763L23 8.77626C16.93 2.70625 7.08 2.70625 1 8.77626ZM9 16.7763L12 19.7763L15 16.7763C13.35 15.1163 10.66 15.1163 9 16.7763ZM5 12.7763L7 14.7763C9.76 12.0163 14.24 12.0163 17 14.7763L19 12.7763C15.14 8.91625 8.87 8.91625 5 12.7763Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}

export default WifiIcon24x24
