import * as React from "react"
import styled from "styled-components"
import { theme } from "../../components/theme"
import H2 from "../atoms/typography/H2"
import { ArrowDropDownIcon } from "../atoms/icons/ArrowDropDownIcon"
import { Spacer } from "../atoms/spacers"
import { Row, Col } from "react-grid-system"
import CardBar from "../atoms/card/CardBar"
import TooltipRow from "./card/TooltipRow"
import IconContainer from "../atoms/icons/IconContainer"

interface SettingsOverviewBarProps {
  title: string
  open: boolean
  onClick: () => void
  icon: React.ReactNode
  tooltip?: string
}

const CardHeaderRow = styled(Row)`
  padding: 0 15px;
  justify-content: space-between;
`

const ClickableCardContainer = styled.div`
  cursor: pointer;
`

const SettingsOverviewBar: React.FunctionComponent<SettingsOverviewBarProps> = ({
  title,
  open,
  onClick,
  icon,
  tooltip,
}) => {
  const [openTooltip, setOpenTooltip] = React.useState(false)

  const handleClickToolTip = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenTooltip(!openTooltip)
  }

  return (
    <ClickableCardContainer onClick={onClick}>
      <CardBar open={open}>
        <CardHeaderRow align="center">
          <Col>
            <Row align="center">
              <IconContainer onClickHelp={tooltip ? handleClickToolTip : undefined}>{icon}</IconContainer>
              <Spacer spacing={1} />
              <H2 color={theme.colors.greyDark}>{title}</H2>
            </Row>
          </Col>
          <ArrowDropDownIcon open={open} />
        </CardHeaderRow>
        {tooltip && <TooltipRow visible={openTooltip} tooltip={tooltip} />}
      </CardBar>
    </ClickableCardContainer>
  )
}

export default SettingsOverviewBar
