import React from "react"
import { useScreenClass } from "react-grid-system"
import styled from "styled-components"

const H1Desktop = styled.div<{ textAlign?: string; color?: string; bold?: boolean }>`
  color: ${(props) => props.color ?? props.theme.colors.greyDark};

  font-family: "Inter", "Inter-fallback", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : 600)};
  line-height: 62px;

  text-align: ${(props) => props.textAlign ?? "left"};
`

const H1Mobile = styled.div<{ textAlign?: string; color?: string; bold?: boolean }>`
  color: ${(props) => props.color ?? props.theme.colors.greyDark};

  font-family: "Inter", "Inter-fallback", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : 600)};
  line-height: 46px;

  text-align: ${(props) => props.textAlign ?? "left"};
`

const H1: React.FunctionComponent<React.PropsWithChildren<{ textAlign?: string; color?: string; bold?: boolean }>> = (
  props,
) => {
  const screen = useScreenClass()

  if (["xs", "sm"].includes(screen)) {
    return <H1Mobile {...props} bold={props.bold} />
  } else {
    return <H1Desktop {...props} bold={props.bold} />
  }
}

export default H1
