import * as React from "react"
import { theme } from "../../theme"

const TxIcon24x24: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.93 4.92981L6.35 6.34981C4.9 7.78981 4 9.78981 4 11.9998C4 14.2098 4.9 16.2098 6.35 17.6498L4.93 19.0698C3.12 17.2598 2 14.7598 2 11.9998C2 9.23981 3.12 6.73981 4.93 4.92981Z"
        fill={theme.colors.white}
      />
      <path
        d="M20 11.9998C20 14.2098 19.1 16.2098 17.65 17.6498L19.07 19.0698C20.88 17.2598 22 14.7598 22 11.9998C22 9.23981 20.88 6.73981 19.07 4.92981L17.65 6.34981C19.1 7.78981 20 9.78981 20 11.9998Z"
        fill={theme.colors.white}
      />
      <path
        d="M6 11.9998C6 13.6598 6.67 15.1598 7.76 16.2398L9.17 14.8298C8.45 14.0998 8 13.0998 8 11.9998C8 10.8998 8.45 9.89981 9.18 9.17981L7.76 7.75981C6.67 8.83981 6 10.3398 6 11.9998Z"
        fill={theme.colors.white}
      />
      <path
        d="M18 11.9998C18 13.6598 17.33 15.1598 16.24 16.2398L14.83 14.8298C15.55 14.0998 16 13.0998 16 11.9998C16 10.8998 15.55 9.89981 14.82 9.17981L16.24 7.75981C17.33 8.83981 18 10.3398 18 11.9998Z"
        fill={theme.colors.white}
      />
      <path
        d="M13 13.4362C13.4534 13.12 13.75 12.5946 13.75 11.9999C13.75 11.0334 12.9665 10.2499 12 10.2499C11.0335 10.2499 10.25 11.0334 10.25 11.9999C10.25 12.5946 10.5466 13.12 11 13.4362V20.9999H13V13.4362Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}

export default TxIcon24x24
