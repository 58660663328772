import * as React from "react"
import PowerIcon from "../../atoms/icons/PowerIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import { pages } from "../../../utils/pages"

const VOLTAGES_TOOLTIP = `
Each power rail powers a set of components in the system. 
Small deviations from the nominal voltage is expected and normal.
Large deviations may cause issues.
`

const VoltagesCard: React.FunctionComponent = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [voltage24V, setVoltage24V] = React.useState(/*24.0*/)
  const [voltage5V, setVoltage5V] = React.useState(/*5.0*/)
  const [voltage3V3, setVoltage3V3] = React.useState(/*3.3*/)
  const [voltage1V8, setVoltage1V8] = React.useState(/*1.8*/)
  const [voltage1V2, setVoltage1V2] = React.useState(/*1.2*/)
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const displayVoltage = (voltage?: number) => (voltage ? `${voltage}V` : undefined)

  return (
    <SettingsOverviewCard title={pages.system.sections[4].name} icon={<PowerIcon />} tooltip={VOLTAGES_TOOLTIP}>
      <ReadOnlyTextField placeholder="-.- V" label="24V Power supply" value={displayVoltage(voltage24V)} />
      <ReadOnlyTextField placeholder="-.- V" label="5V Power supply" value={displayVoltage(voltage5V)} />
      <ReadOnlyTextField placeholder="-.- V" label="3.3V Power supply" value={displayVoltage(voltage3V3)} />
      <ReadOnlyTextField placeholder="-.- V" label="1.8V Power supply" value={displayVoltage(voltage1V8)} />
      <ReadOnlyTextField placeholder="-.- V" label="1.2V Power supply" value={displayVoltage(voltage1V2)} />
    </SettingsOverviewCard>
  )
}

export default VoltagesCard
