import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  on: boolean
}

const WifiIcon: React.FunctionComponent<IconProps> = ({ on }) => {
  return on ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="wifi">
        <path
          id="Vector"
          d="M1.33203 11.7021L3.9987 14.3687C10.6254 7.74208 21.372 7.74208 27.9987 14.3687L30.6654 11.7021C22.572 3.60875 9.4387 3.60875 1.33203 11.7021ZM11.9987 22.3687L15.9987 26.3687L19.9987 22.3687C17.7987 20.1554 14.212 20.1554 11.9987 22.3687ZM6.66536 17.0354L9.33203 19.7021C13.012 16.0221 18.9854 16.0221 22.6654 19.7021L25.332 17.0354C20.1854 11.8887 11.8254 11.8887 6.66536 17.0354Z"
          fill={theme.colors.white}
        />
      </g>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="wifi_off">
        <path
          id="Vector"
          d="M27.9987 16.2271L30.6654 13.5604C25.692 8.58707 18.8387 6.69374 12.3987 7.81374L15.8387 11.2537C20.2387 11.2271 24.652 12.8804 27.9987 16.2271ZM25.332 18.8937C23.892 17.4537 22.1854 16.4271 20.372 15.7871L24.3987 19.8137L25.332 18.8937ZM11.9987 24.2271L15.9987 28.2271L19.9987 24.2271C17.7987 22.0137 14.212 22.0137 11.9987 24.2271ZM4.54536 3.74707L2.66536 5.62707L6.73203 9.69374C4.78536 10.6671 2.9587 11.9471 1.33203 13.5604L3.9987 16.2271C5.6387 14.5871 7.53203 13.3471 9.5587 12.5204L12.5454 15.5071C10.3854 16.0804 8.3587 17.2137 6.66536 18.8937L9.33203 21.5604C11.132 19.7604 13.4787 18.8404 15.852 18.8137L25.292 28.2537L27.172 26.3737L4.54536 3.74707Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default WifiIcon
