import * as React from "react"
import styled from "styled-components"
import { theme } from "../../theme"
import BodyText from "../typography/BodyText"

interface MultiStateButtonProps {
  onClick: (value: string) => void
  options: string[]
  value?: string
  disabled?: boolean
}

const MultiStateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
`

const Button = styled.button<{ isChosen: boolean; disabled?: boolean }>`
  flex: 1;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "auto" : "cursor")};
  padding: 0.625rem 1rem;
  background: ${(props) => (props.isChosen ? theme.gradients.darkButton : theme.gradients.lightButton)};
  box-shadow: 0px 2px 6px 0px rgba(119, 119, 119, 0.25);

  &:first-child {
    border-radius: 5px 0px 0px 5px;
    border-right: 0.5px solid ${theme.colors.greyDisabled};
  }
  &:last-child {
    border-left: 0.5px solid ${theme.colors.greyDisabled};
    border-radius: 0px 5px 5px 0px;
  }

  &:disabled {
    background: ${({ theme, isChosen }) => (isChosen ? theme.colors.greyDisabled : theme.colors.white)};
    border-color: ${({ theme }) => theme.colors.greyDisabled};
  }
`

const MultiStateButton: React.FunctionComponent<MultiStateButtonProps> = ({ onClick, options, value, disabled }) => {
  if (value && !options.includes(value)) {
    throw new Error(`Value ${value} not in options: ${options}`)
  }

  const enabled = value != undefined && !disabled

  return (
    <MultiStateButtonContainer>
      {options.map((option, index) => {
        const isChosen = option === value
        const textColor = isChosen ? theme.colors.greyDark : theme.colors.greyDark
        const textColorDisabled = isChosen ? theme.colors.white : theme.colors.greyDisabled
        return (
          <Button disabled={!enabled} isChosen={isChosen} key={index} onClick={() => onClick(option)}>
            <BodyText weight={!enabled ? "500" : "600"} color={!enabled ? textColorDisabled : textColor}>
              {option}
            </BodyText>
          </Button>
        )
      })}
    </MultiStateButtonContainer>
  )
}

export default MultiStateButton
