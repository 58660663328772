import { AnimatePresence } from "framer-motion"
import * as React from "react"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import Modal from "../../molecules/modals/Modal"
import IconContainer from "../../atoms/icons/IconContainer"
import SatelliteIcon from "../../atoms/icons/SatelliteIcon"
import TooltipRow from "../../molecules/card/TooltipRow"
import H3 from "../../atoms/typography/H3"
import styled from "styled-components"
import SmallCard from "../../atoms/card/SmallCard"
import { theme } from "../../theme"
import BeamConnectionDetails from "../BeamConnectionDetails"

const BEAM_SWITCH_TOOLTIP = `
A beam is a signal which a satellite transmits. Depending on your conditions, 
the auto-selected beam may not be ideal for you. 
If so you can switch to another beam in this list.
`

const PLACEHOLDER_BEAMS = [
  "IS 34e Beam 2",
  "Astra 4A Beam 1",
  "IS 33e Beam 1",
  "IS 22e Beam 2",
  "IS 37e Beam 21",
  "Plupp 12 Apa 3",
  "Plupp 12 Apa 4",
  "Plupp 12 Apa 5",
]

const PLACEHOLDER_DETAILS = [
  { label: "Orbital slot", value: "37° E" },
  { label: "Azimuth", value: "213°" },
  { label: "Elevation", value: "53°" },
  { label: "Polarization", value: "13° | H" },
  { label: "Rx bandwidth", value: "12.945 MHz" },
  { label: "Rx RF frequency", value: "12 353.123 GHz" },
  { label: "Rx LO frequency", value: "10.564 GHz" },
]

interface BeamSwitchModalProps {
  open: boolean
  onClose: () => void
}

interface BeamListProps {
  onSelectBeam: (beamName: string) => void
  selectedBeam: string
  connectedBeam: string
  switchingToBeam?: string
}

const BeamListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  row-gap: 12px;

  // https://stackoverflow.com/a/63514064
  overflow-y: scroll;
  height: 20rem;
  min-height: 100%;
`

const BeamList = ({ onSelectBeam, selectedBeam, connectedBeam, switchingToBeam }: BeamListProps) => (
  <BeamListContainer>
    {PLACEHOLDER_BEAMS.map((beamName, i) => (
      <SmallCard
        clickable
        selected={selectedBeam === beamName}
        key={beamName + i}
        onClick={() => onSelectBeam(beamName)}
      >
        <Row justify="between">
          <H3>{beamName}</H3>
          {!switchingToBeam && connectedBeam === beamName && <H3 color={theme.colors.greenDark}>Connected</H3>}
          {switchingToBeam === beamName && <H3 color={theme.colors.greyDark}>Connecting..</H3>}
        </Row>
      </SmallCard>
    ))}
  </BeamListContainer>
)

const BeamSwitchModal: React.FunctionComponent<BeamSwitchModalProps> = ({ open, onClose }) => {
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const [selectedBeamName, setSelectedBeamName] = React.useState(PLACEHOLDER_BEAMS[0])
  const [connectedBeamName, setConnectedBeamName] = React.useState(PLACEHOLDER_BEAMS[0])
  const [switchingToBeam, setSwitchingToBeam] = React.useState<string | undefined>()

  const switchToBeam = (beamName: string) => {
    setSwitchingToBeam(beamName)
    setTimeout(() => {
      setSwitchingToBeam(undefined)
      setConnectedBeamName(beamName)
    }, 500)
  }

  const handleClickToolTip = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenTooltip(!openTooltip)
  }

  const ModalIcon = () => (
    <IconContainer onClickHelp={handleClickToolTip}>
      <SatelliteIcon />
    </IconContainer>
  )

  const connectionStatus = switchingToBeam ? "connecting" : connectedBeamName === selectedBeamName ? "on" : "off"

  return (
    <AnimatePresence mode="wait">
      {open && (
        <Modal
          title="Beam list"
          onClose={onClose}
          closeIcon
          leftIcon={<ModalIcon />}
          centerTitle={false}
          fixedWidth={true}
        >
          <TooltipRow visible={openTooltip} tooltip={BEAM_SWITCH_TOOLTIP} />
          <HorizontalSpacer spacing={1} />
          <H3>Available beams</H3>
          <HorizontalSpacer spacing={1} />
          <Row align="stretch" style={{ rowGap: "24px" }}>
            <Col xs={12} lg={6}>
              <BeamList
                connectedBeam={connectedBeamName}
                selectedBeam={selectedBeamName}
                switchingToBeam={switchingToBeam}
                onSelectBeam={setSelectedBeamName}
              />
            </Col>
            <Col>
              <BeamConnectionDetails
                name={selectedBeamName}
                details={PLACEHOLDER_DETAILS}
                onConnectBeam={switchToBeam}
                status={connectionStatus}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </AnimatePresence>
  )
}

export default BeamSwitchModal
