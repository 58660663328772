import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import WifiIcon24x24 from "../../atoms/icons/WifiIcon24x24"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import EditableTextField from "../../molecules/inputs/EditableTextField"
import EditablePasswordField from "../../molecules/inputs/EditableSettingsSecretField"
import Dropdown, { DropdownItem } from "../../molecules/inputs/Dropdown"
import TurnOffWifiModal from "../modals/TurnOffWifiModal"
import { pages } from "../../../utils/pages"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"

const availableEncryption = [
  { title: "WPA2", id: "WPA2" },
  { title: "WPA3", id: "WPA3" },
  { title: "WEP", id: "WEP" },
]

const WifiCard: React.FunctionComponent = () => {
  // not sure if we want this was from the figma design, also there was no SSID field.
  const [ssid, setSsid] = React.useState<string>(/*"admin"*/)
  const [password, setPassword] = React.useState<string>(/*"password"*/)

  const [wifiOn, setWifiOn] = React.useState<boolean>(/*true*/)
  const [encryption, setEncryption] = React.useState<DropdownItem<string, string>>(/*availableEncryption[0]*/)

  const [turnOffWifiModalOpen, setTurnOffWifiModalOpen] = React.useState(false)

  const handleWifiToggle = () => {
    if (wifiOn) {
      setTurnOffWifiModalOpen(true)
    } else {
      setWifiOn(true)
    }
  }

  const handleConfirmWifiOff = () => {
    setWifiOn(false)
    setTurnOffWifiModalOpen(false)
  }

  return (
    <>
      <SettingsOverviewCard
        title={pages.overview.sections[3].name}
        data-testid={pages.overview.sections[3].name}
        icon={<WifiIcon24x24 />}
      >
        <ButtonRow label="Wifi on" button={<ToggleButton active={wifiOn} onToggle={handleWifiToggle} />} />
        <EditableTextField label="SSID" value={ssid} onChange={setSsid} />
        <EditablePasswordField label="Password" value={password} onChange={setPassword} />
        <SettingsFieldWithLabel label="Encryption">
          <Dropdown items={availableEncryption} onClickItem={setEncryption} selectedItem={encryption} />
        </SettingsFieldWithLabel>
      </SettingsOverviewCard>
      <TurnOffWifiModal
        open={turnOffWifiModalOpen}
        onClose={() => setTurnOffWifiModalOpen(false)}
        onConfirm={handleConfirmWifiOff}
      />
    </>
  )
}

export default WifiCard
