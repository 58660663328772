import * as React from "react"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"
import H3 from "../../atoms/typography/H3"

interface ReadOnlyTextFieldProps {
  label: string
  value?: string
  secondValue?: string
  placeholder?: string
  secondPlaceholder?: string
  compact?: boolean
  textAlign?: "left" | "center" | "right"
}

const ReadOnlyTextField: React.FunctionComponent<ReadOnlyTextFieldProps> = ({
  label,
  value,
  secondValue,
  textAlign = "left",
  compact,
  placeholder,
  secondPlaceholder,
}) => {
  return (
    <SettingsFieldWithLabel label={label} compact={compact}>
      <H3 textAlign={textAlign} disabled={value == undefined}>
        {value ?? placeholder}
        {secondValue ||
          (secondPlaceholder && (
            <>
              <br />
              {secondValue ?? secondPlaceholder}
            </>
          ))}
      </H3>
    </SettingsFieldWithLabel>
  )
}

export default ReadOnlyTextField
