export const pages = {
  overview: {
    name: "Overview",
    path: "/",
    sections: [
      {
        name: "Satellite signal",
        path: "#satellite-signal",
      },
      {
        name: "System messages",
        path: "#system-messages",
      },
      {
        name: "Position",
        path: "#position",
      },
      {
        name: "Wifi",
        path: "#wifi",
      },
      {
        name: "Transmission",
        path: "#transmission",
      },
    ],
  },
  system: {
    name: "System",
    path: "/system",
    sections: [
      {
        name: "General",
        path: "#general",
      },
      {
        name: "Software",
        path: "#software",
      },
      {
        name: "Modem",
        path: "#modem",
      },
      {
        name: "Temperatures",
        path: "#temperatures",
      },
      {
        name: "Voltages",
        path: "#voltages",
      },
    ],
  },
  support: {
    name: "Support",
    path: "/support",
    sections: [
      {
        name: "Contact",
        path: "#contact",
      },
      {
        name: "Guides and manuals",
        path: "#guides-and-manuals",
      },
      // TODO unhide after mvp
      // {
      //   name: "Error code index",
      //   path: "#error-codes",
      // },
    ],
  },
} as const
