import * as React from "react"
import styled from "styled-components"
import { MediaQuery } from "../../../utils/design-helpers"
import { theme } from "../../theme"
import { CheckmarkIcon } from "../../atoms/icons/CheckboxIcons"
import BodyText from "../../atoms/typography/BodyText"

interface InputFieldProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  defaultValue?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  id?: string
  name?: string
  value?: string | number
  required?: boolean
  type?: React.HTMLInputTypeAttribute
  inputAccepted?: boolean
  tabIndex?: number
  autofocus?: boolean
  errorMessage?: string
  icon?: React.ReactNode
}

const InputFieldStyle = styled.input<{ inputAccepted?: boolean }>`
  outline: none;
  width: 100%;
  font-family: "Karla", sans-serif;
  font-size: 20px;
  border-bottom: ${(props) =>
    props.inputAccepted ? `2px solid ${props.theme.colors.green}` : `2px solid ${theme.colors.greyDark}`};
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: transparent;
  color: ${(props) => props.theme.colors.greyDark};

  ::placeholder {
    color: ${(props) => props.theme.colors.greyPlaceholder};
    font-style: italic;
  }

  :disabled {
    color: ${(props) => props.theme.colors.greyDisabled};
  }

  ${MediaQuery.XS} {
    font-size: 14px;
  }
`

const EndIcon = styled.i`
  position: absolute;
  right: 24px;
  bottom: 8px;
`

const InputField: React.FC<InputFieldProps> = ({
  onChange,
  defaultValue,
  label: textFieldTitle,
  placeholder,
  disabled,
  id,
  name,
  value,
  required,
  type,
  inputAccepted,
  tabIndex,
  autofocus,
  errorMessage,
  icon,
}) => {
  return (
    <div>
      {textFieldTitle && <BodyText>{textFieldTitle}:</BodyText>}
      {inputAccepted && (
        <EndIcon>
          <CheckmarkIcon color={theme.colors.green} />
        </EndIcon>
      )}
      <EndIcon>{icon}</EndIcon>
      <InputFieldStyle
        id={id}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        required={required}
        type={type}
        inputAccepted={inputAccepted}
        tabIndex={tabIndex}
        autoFocus={autofocus}
      />
      {errorMessage && <BodyText color={theme.colors.redDark}>{errorMessage}</BodyText>}
    </div>
  )
}

export default InputField
