import * as React from "react"
import { theme } from "../../theme"

const PowerIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.9951 21H9.99509L10.9951 14H7.49509C6.61509 14 7.16509 13.25 7.18509 13.22C8.47509 10.94 10.4151 7.54 13.0051 3H14.0051L13.0051 10H16.5151C16.9151 10 17.1351 10.19 16.9151 10.66C12.9651 17.55 10.9951 21 10.9951 21Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}

export default PowerIcon
