import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import SystemMessagesIcon from "../../atoms/icons/SystemMessagesIcon"
import ErrorMessageField from "../../molecules/card/ErrorMessageField"
import { ErrorMessage } from "../../../types"
import styled from "styled-components"
import LinkIcon from "../../atoms/icons/LinkIcon"
import { HorizontalSpacer } from "../../atoms/spacers"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"

const SYSTEM_MESSAGES_TOOLTIP = `
Relevant system messages concerning operation of the terminal are presented 
for troubleshooting purposes. The following types of messages can be viewed: 
Error, Status, Warning.
`

const ErrorButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
`

const exampleErrorMessages: ErrorMessage[] = [
  {
    title: "Error message",
    message: "The system has encountered an error",
    dateTime: "12:00 12 Mar 2024",
    severity: "error",
    solution: "Please contact support",
  },
  {
    title: "Warning message",
    message: "The system has encountered a warning",
    dateTime: "12:00 12 Mar 2024",
    severity: "warning",
    solution: "do nothing",
  },
  {
    title: "Another error message",
    message: "The system has encountered another error",
    dateTime: "12:00 12 Mar 2024",
    severity: "error",
    solution: "Please contact support",
  },
]

const SystemMessagesCard: React.FunctionComponent = () => {
  return (
    <SettingsOverviewCard title="System messages" icon={<SystemMessagesIcon />} tooltip={SYSTEM_MESSAGES_TOOLTIP}>
      <div>
        {exampleErrorMessages.map((errorMessage, index) => (
          <ErrorMessageField key={index} {...errorMessage} />
        ))}
      </div>
      <HorizontalSpacer spacing={2} />
      <ErrorButtonsContainer>
        <PrimaryButton title="Open system logs" iconLeft={<LinkIcon />} variant="dark" />
        {/* TODO unhide after mvp */}
        {/* <PrimaryButton title="Error code index" iconLeft={<InfoIcon />} variant="light" /> */}
      </ErrorButtonsContainer>
    </SettingsOverviewCard>
  )
}

export default SystemMessagesCard
