import styled from "styled-components"
import Card from "./Card"
import React from "react"

interface SmallCardProps extends React.PropsWithChildren {
  selected?: boolean
  clickable?: boolean
  backgroundColor?: string
  onClick?: React.MouseEventHandler
}

const SmallCardBase = styled(Card)<{ clickable?: boolean; backgroundColor?: string }>`
  padding-block: 1.5rem;
  padding-inline: 2rem;
  min-width: unset;
  border-radius: ${(props) => props.theme.borders.softRadius};
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor ?? props.theme.colors.white};
  height: 100%;
  cursor: ${(props) => (props.clickable ? "pointer" : "unset")};
`

const BackgroundBorder = styled.div<{ selected?: boolean }>`
  background: ${(props) => (props.selected ? props.theme.gradients.blue : props.theme.colors.white)};
  border-radius: calc(${(props) => props.theme.borders.softRadius} + 3px);
  padding: 3px;
`

const SmallCard: React.FunctionComponent<SmallCardProps> = ({
  selected,
  clickable,
  backgroundColor,
  children,
  onClick,
}) => {
  return (
    <BackgroundBorder selected={selected} onClick={onClick}>
      <SmallCardBase backgroundColor={backgroundColor} clickable={selected && clickable}>
        {children}
      </SmallCardBase>
    </BackgroundBorder>
  )
}

export default SmallCard
