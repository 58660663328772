import React from "react"
import styled from "styled-components"

interface HamburgerProps {
  isOpen: boolean
  onClick: () => void
}

const HamburgerMenuIcon = styled.div<HamburgerProps>`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const HamburgerMenu: React.FC<HamburgerProps> = ({ isOpen, onClick }) => {
  return (
    <HamburgerMenuIcon isOpen={isOpen} onClick={onClick}>
      <div />
      <div />
      <div />
    </HamburgerMenuIcon>
  )
}

export default HamburgerMenu
