import * as React from "react"
import { Col, Hidden, Row, Visible } from "react-grid-system"
import { HorizontalSpacer, Spacer } from "../../atoms/spacers"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ContactPhoneIcon from "../../atoms/icons/ContactPhoneIcon"
import BodyTextMedium from "../../atoms/typography/BodyTextMedium"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import FolderZipIcon from "../../atoms/icons/FolderZipIcon"
import H3 from "../../atoms/typography/H3"
import ExternalLinkIcon from "../../atoms/icons/ExternalLinkIcon"
import { theme } from "../../theme"
import DiagnosticPackageModal from "../modals/DiagnosticPackageModal"
import styled from "styled-components"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"

const LinkText = styled(H3)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyDark};
`

const ContactInformationCard: React.FunctionComponent = () => {
  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <SettingsOverviewCard title="Contact" icon={<ContactPhoneIcon />}>
      <SettingsFieldWithLabel label="Telephone number (Global)">
        <H3 color={theme.colors.greyDarker}> +46 31 701 05 00</H3>
      </SettingsFieldWithLabel>
      {/* TODO unhide after mvp and change to correct phone number */}
      {/* <GenericCardRow>
        <BodyText>Telephone number (US)</BodyText>
        <H3 color={theme.colors.greyDarker}>+1 888 61775 180</H3>
      </GenericCardRow> */}
      <SettingsFieldWithLabel label="Mail">
        <H3 color={theme.colors.greyDarker}>support@satcube.com</H3>
      </SettingsFieldWithLabel>
      <SettingsFieldWithLabel label="Satcube support page">
        <Col xs="content">
          <a href="https://support.satcube.com" rel="noreferrer" target="_blank" style={{ textDecoration: "none" }}>
            <Row justify="end">
              <Hidden xs>
                <ExternalLinkIcon />
                <Spacer spacing={0.5} />
              </Hidden>
              <LinkText color={theme.colors.greyDark}>support.satcube.com</LinkText>
            </Row>
          </a>
        </Col>
      </SettingsFieldWithLabel>
      <HorizontalSpacer spacing={1} />
      <Row>
        <Hidden xs>
          <Col>
            <BodyTextMedium>
              Get support efficiently by sending in your diagnostic package to the Satcube support team
            </BodyTextMedium>
          </Col>
        </Hidden>
        <Visible xs>
          <Col xs="content">
            <HorizontalSpacer spacing={1} />
            <BodyTextMedium>
              Get support efficiently by sending in your diagnostic package to the Satcube support team
            </BodyTextMedium>
            <HorizontalSpacer spacing={2} />
          </Col>
        </Visible>
        <Col xs="content">
          <PrimaryButton title="Diagnostic package" iconLeft={<FolderZipIcon />} onClick={() => setModalOpen(true)} />
          <DiagnosticPackageModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={() => setModalOpen(false)}
          />
        </Col>
      </Row>
    </SettingsOverviewCard>
  )
}

export default ContactInformationCard
