import * as React from "react"
import BodyText from "../typography/BodyText"
import { Col, Row } from "react-grid-system"
import SettingsField from "./SettingsField"
import { theme } from "../../theme"

export interface GenericCardRowWithLabelProps {
  label: string
  children: React.ReactNode
  labelIcon?: React.ReactNode
  labelIconLeft?: React.ReactNode
  compact?: boolean
}

const SettingsFieldWithLabel: React.FunctionComponent<GenericCardRowWithLabelProps> = ({
  label,
  children,
  labelIcon,
  labelIconLeft,
  compact,
}) => {
  return (
    <SettingsField compact={compact}>
      <Row justify="between" align="center" style={{ rowGap: theme.spacings.medium }}>
        <Col xs="content">
          <Row gutterWidth={8} align="center">
            <Col>{labelIconLeft}</Col>
            <BodyText>{label}</BodyText>
            <Col>{labelIcon}</Col>
          </Row>
        </Col>
        <Col xs="content">{children}</Col>
      </Row>
    </SettingsField>
  )
}

export default SettingsFieldWithLabel
