// Simplified wrapper over protobuf metric to simplify fetching of metrics

import { DeviceMetadata, MetricValueMetadata } from "./DeviceMetadata"
import * as metricsProto from "edge-proto/dist/edge/v1/metrics"
import { EdgeError, MissingMetadataError, ProtoMissingFieldError, UnexpectedMetricValue } from "./Errors"

type MetricValuesContainer = { [key: string]: metricsProto.MetricMessage }

interface MetricValue {
  toString(): string | UnexpectedMetricValue
  toFloat(): number | UnexpectedMetricValue
  toRoundedFloat(decimals: number): number | UnexpectedMetricValue
  toEnum<T>(): T | UnexpectedMetricValue
}

// Same as MetricValue interface, but returns undefined in case of any
// errors. Implementation of the interface takes an callback function
// which is called on any errors. The interface is usual for displaying
// values in react, so that no handling of errors is needed.
export interface MetricValueNoError {
  toString(): string | undefined
  toFloat(): number | undefined
  toRoundedFloat(decimals: number): number | undefined
  toEnum<T>(): T | undefined
}

// Contains the most recent received metric values. All operations
// on the object are immutable.
export interface Metrics {
  /**
   * Returns a metric value given a group and value id.
   *
   * @returns the metric value. The value
   * is undefined if the metric value hasn't been received from the
   * edge API yet or the metadata isn't available yet.
   */
  value(groupId: string, valueId: string): MetricValueNoError | undefined
  setMetadata(metadata: DeviceMetadata): Metrics
  // This function should be called when a new metric message is received
  // from the device and returns a new metrics set containing metrics
  // corresponding to the content of the metric message.
  update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError
}

function metricValue(metricValueMetadata: MetricValueMetadata, metricValue: metricsProto.MetricValue): MetricValue {
  const value = metricValue.value

  const error = (msg: string): UnexpectedMetricValue => {
    return new UnexpectedMetricValue(`For metric ${metricValueMetadata.fullId} with value ${value}: ${msg}`)
  }

  const missingValueError = () => error("Metric value is missing in proto definition")

  const unexpectedTypeError = (expectedType: string) =>
    error(`Got unexpected type ${value?.$case}, but expected a ${expectedType}`)

  const toString = (): string | UnexpectedMetricValue => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "stringValue":
        return value.stringValue
      case "floatValue":
        return value.floatValue.toString()
      case "boolValue":
        return value.boolValue.toString()
      case "int32Value":
        return value.int32Value.toString()
      case "uint32Value":
        return value.uint32Value.toString()
      case "timestampValue":
        return value.timestampValue.toString()
    }
  }

  const toFloat = (): number | UnexpectedMetricValue => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "floatValue": {
        return value.floatValue
      }
      default:
        return unexpectedTypeError("float")
    }
  }

  return {
    toString: toString,
    toEnum<T>(): T | UnexpectedMetricValue {
      const validator = metricValueMetadata.validator
      const stringValue = toString()

      if (stringValue instanceof UnexpectedMetricValue) {
        return stringValue
      }

      switch (validator.$case) {
        case "enum":
          if (!validator.isValid(stringValue)) {
            return error(`Expected enum value to be one of ${validator.expectedValues}`)
          }
          return stringValue as T
        default:
          return unexpectedTypeError("enum")
      }
    },
    toFloat: toFloat,
    toRoundedFloat(decimals: number): number | UnexpectedMetricValue {
      const floatValue = toFloat()
      if (floatValue instanceof UnexpectedMetricValue) {
        return floatValue
      }
      return Number(floatValue.toFixed(decimals))
    },
  }
}

function metricValueNoErro(valueMetric: MetricValue, onError: (error: EdgeError) => void): MetricValueNoError {
  return {
    toString(): string | undefined {
      const value = valueMetric.toString()
      if (value instanceof UnexpectedMetricValue) {
        onError(value)
        return undefined
      }
      return value
    },
    toEnum<T>(): T | undefined {
      const value = valueMetric.toEnum<T>()
      if (value instanceof UnexpectedMetricValue) {
        onError(value)
        return undefined
      }
      return value
    },
    toFloat(): number | undefined {
      const value = valueMetric.toFloat()
      if (value instanceof UnexpectedMetricValue) {
        onError(value)
        return undefined
      }
      return value
    },
    toRoundedFloat(decimals: number): number | undefined {
      const value = valueMetric.toRoundedFloat(decimals)
      if (value instanceof UnexpectedMetricValue) {
        onError(value)
        return undefined
      }
      return value
    },
  }
}

function newMetricsWithValues(
  values: MetricValuesContainer,
  onError: (error: EdgeError) => void,
  metadata?: DeviceMetadata,
): Metrics {
  const valueWithError = function (
    groupId: string,
    valueId: string,
  ): MetricValue | undefined | MissingMetadataError | ProtoMissingFieldError {
    if (metadata === undefined) {
      return undefined
    }
    const metricMessage = values[groupId]
    if (metricMessage === undefined) {
      return undefined
    }
    const metricGroup = metadata.metricGroup(groupId)
    if (metricGroup instanceof MissingMetadataError) {
      return metricGroup
    }
    const metricValueMetadata = metricGroup.value(valueId)
    if (metricValueMetadata === undefined) {
      return new MissingMetadataError(`Could not find metric in group ${groupId} with id ${valueId}`)
    }
    const metricSet = metricMessage.metricSet
    if (metricSet === undefined) {
      return new ProtoMissingFieldError("MetricMesssage", "MetricGroup")
    }
    return metricValue(metricValueMetadata, metricSet.metricValues[metricValueMetadata.index])
  }

  return {
    value(groupId: string, valueId: string): MetricValueNoError | undefined {
      const value = valueWithError(groupId, valueId)
      if (value instanceof EdgeError) {
        onError(value)
        return undefined
      }
      if (value === undefined) {
        return undefined
      }
      return metricValueNoErro(value, onError)
    },
    setMetadata(metadata: DeviceMetadata): Metrics {
      return newMetricsWithValues(values, onError, metadata)
    },
    update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError {
      const metricGroup = metricMessage.metricSet
      if (metricGroup === undefined) {
        return new ProtoMissingFieldError("MetricMessage", "MetricGroup")
      }

      return newMetricsWithValues({ ...values, ...{ [metricGroup.id]: metricMessage } }, onError, metadata)
    },
  }
}

export function newMetrics(onError: (error: EdgeError) => void): Metrics {
  return newMetricsWithValues({}, onError, undefined)
}
