import * as React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

interface ButtonProps {
  title: string
  onClick?: () => void
  color?: string
  icon?: React.ReactNode
  iconRight?: React.ReactNode
}

const ButtonStyle = styled.div<{ color: string }>`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  border: none;
  background-color: none;

  :hover {
    text-decoration-line: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: ${(props) => props.theme.spacings.small};
  }
`

const TextButton: React.FunctionComponent<ButtonProps> = ({
  title,
  onClick,
  icon,
  color = theme.colors.greyDark,
  iconRight,
}) => {
  return (
    <ButtonStyle onClick={onClick} color={color}>
      {icon}
      {title}
      {iconRight}
    </ButtonStyle>
  )
}

export default TextButton
