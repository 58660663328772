export const theme = {
  colors: {
    greyDark: "#616161",
    greyDarker: "#444444",
    greyDarkest: "#262626",
    greyLight: "#F4F4F4",
    greyLighter: "#FAFAFA",
    greyPlaceholder: "#ADADAD",
    greyDisabled: "#C3C3C3",
    purple: "#9747FF",
    white: "#FFFFFF",
    red: "#E35353",
    background: "#F5F5F5",
    green: "#3DAE8B",
    greenDark: "#2B8267",
    yellow: "#FFEDBE",
    blue: "#B8C7D6",
    graphColorBlue: "#6D7D8D",
    redDark: "#B64A4A",
    crayonGreen: "#99D9C5",
    crayonRed: "#EB7F7F",
    buttonLight: "#F7F7F7",
    disabledBar: "#E2EAF3",
  },
  borders: {
    smallRadius: "4px",
    buttonRadius: "5px",
    softRadius: "12px",
    largeRadius: "24px",
    smallWidth: "1px",
  },
  spacings: {
    tiny: "2px",
    small: "4px",
    medium: "0.5rem",
    large: "1rem",
    extreme: "2rem",
    larger: "3rem",
    huge: "6.5rem",
  },
  containers: {
    small: "900px",
    medium: "1200px",
    large: "1400px",
  },
  gradients: {
    blue: "linear-gradient(68deg, #81A6C8 0%, #97D1D9 100%)",
    red: "linear-gradient(68deg, #C67C66 0%, #EAAD81 100%)",
    yellow: "linear-gradient(68deg, #EABB90 0%, #F3EB73 100%)",
    green: "linear-gradient(68deg, #80B67D 0%, #AED37C 100%)",
    lightButton: "linear-gradient(180deg, #FFF 0%, #F5F5F5 100%)",
    darkButton: "linear-gradient(0deg, #616161 0%, #4F4F4F 100%)",
    greenBorder: "#80B67D",
    blueBorder: "#81a6c8",
    redBorder: "#C67C66",
    yellowBorder: "#EABB90",
  },
}

export type Theme = typeof theme
