import * as React from "react"
import styled from "styled-components"

interface ButtonProps {
  onToggle: () => void
  active?: boolean
  disabled?: boolean
}

interface TogglePartProps {
  active: boolean
}

const ToggleContainer = styled.div<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? undefined : "pointer")};
  width: 64px;
  height: 40px;
  position: relative;
`

const RectEnabled = styled.div<TogglePartProps>`
  width: 52px;
  height: 24px;
  left: 6px;
  top: 6px;
  position: absolute;
  opacity: 0.8;
  background: ${(props) => (props.active ? "linear-gradient(97deg, #81a6c8 0%, #97d1d9 100%)" : "#C3C3C3")};
  box-shadow: 0px 2px 5px rgba(37.19, 37.19, 37.19, 0.25) inset;
  border-radius: 20px;
  transition: background 0.3s ease-out;
`

const CircleEnabled = styled.div<TogglePartProps>`
  width: 28px;
  height: 28px;
  left: ${(props) => (props.active ? "30px" : "6px")};
  top: 4px;
  position: absolute;
  background: linear-gradient(180deg, #595959 0%, #454545 100%);
  box-shadow: 0px 2px 6px rgba(119, 119, 119, 0.25);
  border-radius: 9999px;
  transition: left 0.3s ease-out;
`

const RectDisabled = styled.div<TogglePartProps>`
  width: 52px;
  height: 24px;
  left: 6px;
  top: 6px;
  position: absolute;
  opacity: 0.8;
  background: #fafafa;
  box-shadow: 0px 2px 5px rgba(37.19, 37.19, 37.19, 0.25) inset;
  border-radius: 20px;
`

const CircleDisabled = styled.div<TogglePartProps>`
  width: 28px;
  height: 28px;
  left: ${(props) => (props.active ? "30px" : "6px")};
  top: 4px;
  position: absolute;
  background: #c3c3c3;
  box-shadow: 0px 2px 6px rgba(119, 119, 119, 0.25);
  border-radius: 9999px;
`

const ToggleButton: React.FunctionComponent<ButtonProps> = ({ onToggle, active, disabled = false }) => {
  const enabled = active != undefined && !disabled

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === " ") {
      onToggle()
      e.preventDefault()
    }
  }

  return (
    <ToggleContainer
      tabIndex={0}
      onKeyDown={handleKeyDown}
      data-testid="toggle"
      onClick={enabled ? onToggle : undefined}
      disabled={!enabled}
    >
      {!disabled ? (
        <>
          <RectEnabled active={enabled} />
          <CircleEnabled active={enabled} />
        </>
      ) : (
        <>
          <RectDisabled active={enabled} />
          <CircleDisabled active={enabled} />
        </>
      )}
    </ToggleContainer>
  )
}

export default ToggleButton
