import * as React from "react"
import { theme } from "../../theme"

const BeamSwitchIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M9.5 3L5.5 6.99H8.5V14H10.5V6.99H13.5L9.5 3ZM16.5 17.01V10H14.5V17.01H11.5L15.5 21L19.5 17.01H16.5Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}

export default BeamSwitchIcon
