import * as React from "react"
import { theme } from "../../theme"

interface CircleGraphProps {
  strength: number
}

const CircleGraph: React.FunctionComponent<CircleGraphProps> = ({ strength }) => {
  const radius = 33.3333
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (strength / 100) * circumference

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
      <g transform="rotate(90 50 50)">
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke={theme.colors.disabledBar}
          strokeWidth="32"
          fill="none"
          opacity="1.0"
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke="url(#paint0_linear_1381_8929)"
          strokeWidth="32"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1381_8929"
          x1="18.1818"
          y1="5.72687"
          x2="28.8447"
          y2="91.5918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#97D1D9" />
          <stop offset="1" stopColor="#81A6C8" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CircleGraph
