import * as React from "react"
import styled from "styled-components"
import H3 from "../typography/H3"
import ButtonTextSmall from "../typography/ButtonTextSmall"
import {
  ColorVariant,
  getVariantColor,
  getVariantBorderColor,
  getVariantTextColor,
  getVariantHoverColor,
  isColorfulVariant,
} from "../../../utils/theme-helpers"

interface ButtonProps extends ButtonContainerProps {
  onClick?: () => void
  title: string
  type?: "button" | "submit" | "reset"
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  disabled?: boolean
}

interface ButtonContainerProps {
  fullWidth?: boolean
  variant?: ColorVariant
  size?: "big" | "normal" | "small"
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  box-shadow: 0px 1px 3px 1px rgba(119, 119, 119, 0.25);
  border: ${({ theme, variant }) =>
    `${!isColorfulVariant(variant) ? theme.borders.smallWidth : "0"} solid ${getVariantBorderColor(variant)}`};
  border-radius: ${({ theme }) => theme.borders.buttonRadius};
  background: ${({ variant }) => getVariantColor(variant)};
  transition: ${({ variant }) => (!isColorfulVariant(variant) ? "background-color" : "none")} 0.3s ease-out;

  padding: ${({ size }) => (size === "small" ? "8px 16px" : "16px 30px")};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ size }) => (size === "small" ? "4px" : "8px")};

  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:active:not(:disabled) {
    box-shadow: none;
  }

  &:hover:not(:disabled) {
    background: ${({ variant }) => getVariantHoverColor(variant)};
  }
`

const PrimaryButton: React.FunctionComponent<ButtonProps> = ({
  iconLeft,
  iconRight,
  title,
  variant = "dark",
  size = "normal",
  ...props
}) => {
  const Text = size === "normal" ? H3 : ButtonTextSmall

  return (
    <ButtonContainer {...props} variant={variant} size={size}>
      {iconLeft}
      <Text color={getVariantTextColor(variant)}>{title}</Text>
      {iconRight}
    </ButtonContainer>
  )
}

export default PrimaryButton
