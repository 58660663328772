import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import LocationIcon24x24 from "../../atoms/icons/LocationIcon24x24"
import MultiStateButton from "../../atoms/buttons/MultiStateButton"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import ButtonRow from "../../molecules/card/ButtonRow"
import { Latitude, Longitude, coordinateToString } from "../../../utils/coordinates"
import EditableCoordinateField from "../../molecules/inputs/EditableSettingsCoordinateField"

const POSITION_CARD_TOOLTIP = `
It is recommended to use automatic position. Using manual position is not 
recommended while moving, as it may cause the antenna to be pointed in 
the wrong direction. The number of connected satellites is an indication 
for how reliable the coordinates are. A higher number is better, 3 is minimum 
to receive location, 14 is max.
`

const OPTIONS = ["Automatic", "Manual"]

const PositionCard: React.FunctionComponent<{ latitude?: Latitude; longitude?: Longitude }> = (props) => {
  const [position, setPosition] = React.useState<string>(/*OPTIONS[0]*/)
  const [latitude, setLatitude] = React.useState<Latitude | undefined>(props.latitude)
  const [longitude, setLongitude] = React.useState<Longitude | undefined>(props.longitude)
  return (
    <SettingsOverviewCard title="Position" icon={<LocationIcon24x24 />} tooltip={POSITION_CARD_TOOLTIP}>
      <ButtonRow
        label="Coordinates"
        button={<MultiStateButton options={OPTIONS} onClick={setPosition} value={position} />}
      />
      {position === "Manual" && latitude && longitude ? (
        <>
          <EditableCoordinateField type="latitude" onChange={setLatitude} value={latitude} />
          <EditableCoordinateField type="longitude" onChange={setLongitude} value={longitude} />
        </>
      ) : (
        <>
          <ReadOnlyTextField label="Latitude" value={latitude && coordinateToString(latitude)} placeholder="--- -" />
          <ReadOnlyTextField label="Longitude" value={longitude && coordinateToString(longitude)} placeholder="--- -" />
        </>
      )}
    </SettingsOverviewCard>
  )
}

export default PositionCard
