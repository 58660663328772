import React, { createContext, useState, useContext, useEffect, useRef } from "react"
import { Ws } from "../websocket/websocket"
import * as protoMetrics from "edge-proto/dist/edge/v1/metrics"
import * as protoDeviceMetadata from "edge-proto/dist/edge/v1/device_metadata"
import { Metrics, newMetrics } from "./Metrics"
import { ProtoMissingFieldError } from "./Errors"
import { DeviceMetadata, parseMetadata } from "./DeviceMetadata"
import { EDGE_WS_METRICS, EDGE_WS_EVENTS, EDGE_HTTP_METADATA } from "./endpoints"
import { useLogin } from "../auth/LoginProvider"
import { clearUser } from "../redux/slices/userSlice"
import { useDispatch } from "react-redux"
import { StatusCodes } from "http-status-codes"

interface EdgeProviderType {
  metrics: Metrics
  events: { [key: string]: number }
  connected: boolean
}

const EdgeContext = createContext<EdgeProviderType | undefined>(undefined)

const useDeviceMetadata = (isLoggedIn: boolean, onDeviceMetadata: (deviceMetadata: DeviceMetadata) => void) => {
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchMetadata() {
      if (!isLoggedIn) {
        return
      }
      // TODO: Handle error
      const response = await fetch(EDGE_HTTP_METADATA, { credentials: "include" }).catch((_err) => {
        console.log(_err)
      })
      // TODO: Handle error
      if (response === undefined) {
        return
      }
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          dispatch(clearUser("api_unauthorized"))
        }
        return
      }
      const bodyBuffer = await response.arrayBuffer()
      const deviceMetadataMessage = protoDeviceMetadata.DeviceMetadataMessage.decode(new Uint8Array(bodyBuffer))
      const deviceMetadata = parseMetadata(deviceMetadataMessage)
      if (deviceMetadata instanceof ProtoMissingFieldError) {
        // TODO: Handle error
        console.log(`Missing field in DeviceMetadataMessage: ${deviceMetadata}`)
        return
      }
      onDeviceMetadata(deviceMetadata)
    }
    fetchMetadata()
    return
  }, [isLoggedIn])
  return
}

const useMetrics = (isLoggedIn: boolean): [Metrics, boolean, (deviceMetdata: DeviceMetadata) => void] => {
  // TODO: Handle error
  const [metrics, setMetrics] = useState(
    newMetrics((error) => {
      console.log(`Unexpected error: ${error}`)
    }),
  )
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      setMetrics((prevMetrics) => {
        // TODO: Handle decode issues
        const metricMessage = protoMetrics.MetricMessage.decode(new Uint8Array(ev.data))
        const newMetrics = prevMetrics.update(metricMessage)
        if (newMetrics instanceof ProtoMissingFieldError) {
          // TODO: Show error here
          console.log("Protobuf metric message is missing a field")
          return prevMetrics
        }
        return newMetrics
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (_: Ws): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_METRICS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  return [
    metrics,
    connected,
    (deviceMetadata) => {
      setMetrics((prevMetrics) => prevMetrics.setMetadata(deviceMetadata))
    },
  ]
}

const useEvents = (isLoggedIn: boolean): [{ [key: string]: number }, boolean] => {
  const [events, setEvents] = useState({ count: 0 })
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (): void => {
      setEvents((prevEvents) => {
        // TODO: Here we should parse protobuf message and
        // put in this object once we implement protobuf
        // parsing.
        return { count: prevEvents.count + 1 }
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_EVENTS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])
  return [events, connected]
}

export const EdgeProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useLogin()
  const [metrics, metricsConnected, setDeviceMetadata] = useMetrics(isLoggedIn)
  // Events are currently not implemented. This can be seen in the developer
  // console.
  const [events, _eventsConnected] = useEvents(isLoggedIn)
  useDeviceMetadata(isLoggedIn, setDeviceMetadata)
  // TODO: metricsConnected && eventsConnected
  const connected = metricsConnected

  return <EdgeContext.Provider value={{ metrics, events, connected }}>{children}</EdgeContext.Provider>
}

export const useEdgeApi = () => {
  const context = useContext(EdgeContext)
  if (!context) {
    throw new Error("useEdgeApi must be used within a EdgeProvider")
  }
  return context
}
