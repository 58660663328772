import * as React from "react"

const ExternalLinkIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.06667 16.1778L7.82222 14.9333L11.6444 11.1111H8.44444V9.33333H14.6667V15.5556H12.8889V12.3556L9.06667 16.1778ZM18.2222 12V5.77778H12V4H20V12H18.2222ZM5.77778 20C5.28889 20 4.87037 19.8259 4.52222 19.4778C4.17407 19.1296 4 18.7111 4 18.2222V4H5.77778V18.2222H20V20H5.77778Z"
        fill="#616161"
      />
    </svg>
  )
}

export default ExternalLinkIcon
