import styled from "styled-components"

const SettingsField = styled.div.attrs<{ compact?: boolean }>({
  className: "settings-field",
})`
  border-bottom: ${(props) => `1px solid ${props.theme.colors.greyDisabled}`};
  padding: ${({ compact }) => (compact ? "12px" : "16px")} 0;

  &:last-of-type {
    border-bottom: none;
  }
`

export const SettingsFieldDivider = styled.div<{ compact?: boolean }>`
  margin-inline: ${({ compact }) => (compact ? "12px" : "16px")};
  width: 100%;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.greyDisabled}`};
`

export default SettingsField
