import * as React from "react"
import { theme } from "../../theme"

const QuestionMarkIcon: React.FunctionComponent = () => {
  return (
    <svg
      data-testid="question-mark"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="1" y="1" width="18" height="18" rx="9" fill="white" />
      <rect x="1" y="1" width="18" height="18" rx="9" stroke={theme.colors.greyDark} strokeWidth="2" />
      <path
        d="M8.79648 12.184V11.5199C8.79648 11.0559 8.84982 10.6746 8.95648 10.3759C9.06315 10.0773 9.23115 9.82395 9.46048 9.61595C9.69515 9.40262 10.0072 9.19728 10.3965 8.99995C10.7378 8.82395 11.0045 8.66128 11.1965 8.51195C11.3885 8.35728 11.5245 8.19728 11.6045 8.03195C11.6845 7.86662 11.7245 7.67195 11.7245 7.44795C11.7245 7.09062 11.5778 6.79462 11.2845 6.55995C10.9965 6.31995 10.5992 6.19995 10.0925 6.19995C9.50582 6.19995 9.05515 6.32528 8.74048 6.57595C8.43115 6.82662 8.24715 7.16528 8.18848 7.59195H6.39648C6.46048 6.70128 6.79915 5.98928 7.41248 5.45595C8.02582 4.91728 8.89782 4.64795 10.0285 4.64795C10.7645 4.64795 11.3992 4.77328 11.9325 5.02395C12.4712 5.27462 12.8845 5.61328 13.1725 6.03995C13.4605 6.46128 13.6045 6.93595 13.6045 7.46395C13.6045 8.01862 13.4632 8.48795 13.1805 8.87195C12.9032 9.25062 12.4658 9.59195 11.8685 9.89595C11.5165 10.0773 11.2418 10.2479 11.0445 10.4079C10.8472 10.5626 10.7085 10.7386 10.6285 10.9359C10.5485 11.1279 10.5085 11.3733 10.5085 11.6719V12.184H8.79648ZM9.69248 15.3519C9.33515 15.3519 9.04448 15.2426 8.82048 15.0239C8.59648 14.7999 8.48448 14.5279 8.48448 14.2079C8.48448 13.8773 8.59648 13.5999 8.82048 13.3759C9.04448 13.1466 9.33248 13.0319 9.68448 13.0319C10.0418 13.0319 10.3378 13.144 10.5725 13.368C10.8125 13.592 10.9325 13.8693 10.9325 14.2C10.9325 14.5199 10.8125 14.792 10.5725 15.016C10.3378 15.24 10.0445 15.3519 9.69248 15.3519Z"
        fill={theme.colors.greyDark}
      />
    </svg>
  )
}

export default QuestionMarkIcon
