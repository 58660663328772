import * as React from "react"
import TemperatureIcon from "../../atoms/icons/TemperatureIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import ButtonRow from "../../molecules/card/ButtonRow"
import MultiStateButton from "../../atoms/buttons/MultiStateButton"
import { pages } from "../../../utils/pages"

const TEMPERATURES_TOOLTIP = `
Note: Turning the fans off may cause the system to overheat, use fan settings with caution. 
When “Auto” is enabled, the fans activate when temperatures get too high, 
and deactivate when the system is within optimal operating temperatures. 
“Off” disables all fans, and “Max” runs all fans at max effect.
`

const TemperaturesCard: React.FunctionComponent = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // assuming all temps are in celsius
  const [boardTemperature, setBoardTemperature] = React.useState(/*65*/)
  const [modemTemperature, setModemTemperature] = React.useState(/*65*/)
  const [elevationMotorTemperature, setElevationMotorTemperature] = React.useState(/*45*/)
  const [azimuthMotorTemperature, setAzimuthMotorTemperature] = React.useState(/*45*/)
  const [bucAmp1Temperature, setBucAmp1Temperature] = React.useState(/*45*/)
  const [bucAmp2Temperature, setBucAmp2Temperature] = React.useState(/*45*/)
  // What is UI board temp??
  const [uiBoardTemperature, setUiBoardTemperature] = React.useState(/*45*/)
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const fanOptions = ["Off", "Auto", "Max"]
  const [fanSetting, setFanSetting] = React.useState<string>(/*fanOptions[1]*/)

  const celsiusToFahrenheit = (celsius: number) => {
    const fahrenheit = (celsius * 9) / 5 + 32
    return fahrenheit
  }
  const displayCelsiusAndFarenheit = (celsius?: number) =>
    celsius ? `${celsius}°C / ${celsiusToFahrenheit(celsius)}°F` : undefined

  return (
    <SettingsOverviewCard
      title={pages.system.sections[3].name}
      icon={<TemperatureIcon />}
      tooltip={TEMPERATURES_TOOLTIP}
    >
      <ReadOnlyTextField
        placeholder="-- -"
        label="Parental board"
        value={displayCelsiusAndFarenheit(boardTemperature)}
      />
      <ReadOnlyTextField placeholder="-- -" label="Modem" value={displayCelsiusAndFarenheit(modemTemperature)} />
      <ReadOnlyTextField
        placeholder="-- -"
        label="Elevation motor"
        value={displayCelsiusAndFarenheit(elevationMotorTemperature)}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="Azimuth motor"
        value={displayCelsiusAndFarenheit(azimuthMotorTemperature)}
      />
      <ReadOnlyTextField placeholder="-- -" label="BUC Amp 1" value={displayCelsiusAndFarenheit(bucAmp1Temperature)} />
      <ReadOnlyTextField placeholder="-- -" label="BUC Amp 2" value={displayCelsiusAndFarenheit(bucAmp2Temperature)} />
      <ReadOnlyTextField placeholder="-- -" label="UI board" value={displayCelsiusAndFarenheit(uiBoardTemperature)} />
      <ButtonRow
        label="Fans"
        button={<MultiStateButton onClick={setFanSetting} options={fanOptions} value={fanSetting} />}
      />
    </SettingsOverviewCard>
  )
}

export default TemperaturesCard
