import * as React from "react"
import LoginBackground from "../../images/LoginBackground.webp"
import BodyText from "../atoms/typography/BodyText"
import PrimaryButton from "../atoms/buttons/PrimaryButton"
import H3 from "../atoms/typography/H3"
import styled from "styled-components"
import InputField from "../molecules/inputs/InputField"
import { Breakpoints } from "../../utils/design-helpers"
import { Navigate, useNavigate } from "react-router-dom"
import { useLogin } from "../../auth/LoginProvider"

const Background = styled.div`
  background: url(${LoginBackground}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const LoginBox = styled.div`
  background: #fafafa;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 690px;
  padding: 32px;
  gap: 24px;
  align-items: center;
  margin-top: 15%;

  @media (max-width: ${Breakpoints.SM}px) {
    width: 100%;
    margin: 0 2rem;
    margin-top: 15%;
  }
`

const LoginInputBox = styled.div`
  display: flex;
  height: 70px;
  min-width: 172px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`

const Form = styled.form`
  display: inherit;
  flex-direction: inherit;
  width: inherit;
  gap: inherit;
`

const SATCUBE_SUPPORT_EMAIL = "support@satcube.com"
const SATCUBE_SUPPORT_PHONE = "+46 31 701 05 00"

const LoginPage: React.FunctionComponent = () => {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const { login, isLoggedIn } = useLogin()
  const navigate = useNavigate()

  React.useEffect(() => {
    document.title = "Log in"
  }, [])

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    const loginResult = await login(username, password)
    if (loginResult.result !== "success") {
      alert(loginResult.errorMessage)
      return
    }
    navigate("/")
  }

  return (
    <Background>
      <LoginBox>
        <Form onSubmit={handleLogin}>
          <LoginInputBox>
            <H3>Username:</H3>
            <div style={{ width: "100%" }}>
              <InputField id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
          </LoginInputBox>
          <LoginInputBox>
            <H3>Password:</H3>
            <div style={{ width: "100%" }}>
              <InputField
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </LoginInputBox>
          <PrimaryButton title="Log in" type="submit" fullWidth />
        </Form>
        <BodyText>{`Trouble signing in? Contact us at ${SATCUBE_SUPPORT_EMAIL} or ${SATCUBE_SUPPORT_PHONE}`}</BodyText>
      </LoginBox>
    </Background>
  )
}

export default LoginPage
