import * as React from "react"
import GenericConfirmationModal from "../../molecules/modals/GenericConfirmationModal"

interface TurnOffTxModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const TurnOffTxModal: React.FunctionComponent<TurnOffTxModalProps> = ({ open, onClose, onConfirm }) => {
  return (
    <GenericConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={"Turn transmission off"}
      message={`
      Turning transmission off will disconnect the terminal from the satellite and the internet. 
      The terminal will remain on and ready to reconnect. 
      `}
      confirmText={"Turn transmission off"}
    />
  )
}

export default TurnOffTxModal
