import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  gpsOn: boolean
}

export const LocationIcon: React.FC<IconProps> = ({ gpsOn }) => {
  return gpsOn ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="location_on">
        <path
          id="Vector"
          d="M16.0013 2.6665C10.8413 2.6665 6.66797 6.83984 6.66797 11.9998C6.66797 18.9998 16.0013 29.3332 16.0013 29.3332C16.0013 29.3332 25.3346 18.9998 25.3346 11.9998C25.3346 6.83984 21.1613 2.6665 16.0013 2.6665ZM16.0013 15.3332C14.1613 15.3332 12.668 13.8398 12.668 11.9998C12.668 10.1598 14.1613 8.6665 16.0013 8.6665C17.8413 8.6665 19.3346 10.1598 19.3346 11.9998C19.3346 13.8398 17.8413 15.3332 16.0013 15.3332Z"
          fill="white"
        />
      </g>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit_location">
        <path
          id="Vector"
          d="M23.5574 7.20186L22.1307 5.77519L14.7307 13.1752V14.6152H16.1707L23.5574 7.20186ZM22.3841 2.58853C22.5688 2.40147 22.7889 2.25296 23.0315 2.1516C23.2741 2.05024 23.5345 1.99805 23.7974 1.99805C24.0603 1.99805 24.3206 2.05024 24.5633 2.1516C24.8059 2.25296 25.0259 2.40147 25.2107 2.58853L26.7441 4.12186C27.5307 4.90853 27.5307 6.17519 26.7441 6.94853L17.0374 16.6552H12.6641V12.3085L22.3841 2.58853ZM17.6241 3.5752L15.1974 6.00186C10.7974 6.06853 7.33073 9.46853 7.33073 14.2685C7.33073 17.3885 9.93073 21.5219 15.3307 26.4552C20.7307 21.5219 23.3307 17.4019 23.3307 14.2685V14.1352L25.7307 11.7352C25.9041 12.5352 25.9974 13.3885 25.9974 14.2685C25.9974 18.6952 22.4374 23.9352 15.3307 30.0019C8.22406 23.9352 4.66406 18.6952 4.66406 14.2685C4.66406 7.62853 9.73073 3.33519 15.3307 3.33519C16.1041 3.33519 16.8774 3.41519 17.6241 3.5752Z"
          fill={theme.colors.white}
        />
      </g>
    </svg>
  )
}

export default LocationIcon
