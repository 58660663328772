import * as React from "react"
import styled from "styled-components"
import BodyText from "../../atoms/typography/BodyText"
import { HorizontalSpacer, Spacer } from "../../atoms/spacers"
import H3 from "../../atoms/typography/H3"
import { ArrowDropDownIcon } from "../../atoms/icons/ArrowDropDownIcon"
import { SlideOutHeight } from "../../atoms/animations/SlideOutHeight"
import { ErrorMessage } from "../../../types"
import { theme } from "../../theme"
import SettingsField from "../../atoms/card/SettingsField"

interface ErrorMessageFieldProps extends ErrorMessage {
  isLast?: boolean
}

const ErrorMessageFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`

const ErrorMessageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const ErrorTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ErrorMessageField: React.FunctionComponent<ErrorMessageFieldProps> = ({
  message,
  title,
  dateTime,
  severity = "error",
  solution,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <SettingsField>
      <ErrorMessageFieldContainer
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <ErrorTitleContainer>
          {dateTime && (
            <>
              <BodyText>{dateTime}</BodyText>
              <Spacer spacing={1} />
            </>
          )}
          <H3 color={theme.colors.greyDarker}>{title}</H3>
        </ErrorTitleContainer>
        <ArrowDropDownIcon open={expanded} />
      </ErrorMessageFieldContainer>
      <SlideOutHeight isVisible={expanded}>
        <ErrorMessageInnerContainer>
          <H3>{message}</H3>
          <BodyText>Severity: {severity}</BodyText>
          {solution && <BodyText>{solution}</BodyText>}
          <HorizontalSpacer spacing={0.5} />
        </ErrorMessageInnerContainer>
      </SlideOutHeight>
    </SettingsField>
  )
}

export default ErrorMessageField
