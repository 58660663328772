import styled from "styled-components"

const BodyTextMedium = styled.div<{
  textAlign?: string
  color?: string
  opacity?: string
  weight?: string
}>`
  color: ${(props) => props.color ?? props.theme.colors.greyDark};
  text-align: ${(props) => props.textAlign ?? "left"};
  opacity: ${(props) => props.opacity ?? "1"};

  font-family: "Karla", "Karla-fallback", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: ${(props) => props.weight ?? "500"};
  line-height: 25px;
`

export default BodyTextMedium
