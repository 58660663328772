import * as React from "react"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"
import H3 from "../../atoms/typography/H3"
import EditPenIcon from "../../atoms/icons/EditPenIcon"
import { Col, Row } from "react-grid-system"
import styled from "styled-components"
import { theme } from "../../theme"

interface EditableFieldProps extends React.PropsWithChildren {
  label: string
  value: string
  icon?: React.ReactNode
  isSecret?: boolean
  labelIcon?: React.ReactNode
  isEditing: boolean
  disabled?: boolean
  onChangeIsEditing?: (startEditing: boolean) => void
}

const EditButtonStyle = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  outline: none;
  border: none;

  &:focus {
    outline: black solid 2px;
  }
`

const EditableField: React.FunctionComponent<EditableFieldProps> = ({
  label,
  value,
  icon = <EditPenIcon />,
  isSecret = false,
  labelIcon,
  children,
  isEditing,
  onChangeIsEditing,
  disabled,
}) => {
  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === " " || e.key === "Enter") {
      onChangeIsEditing?.(true)
      e.preventDefault()
    }
  }

  const handleClickEditIcon = () => {
    if (disabled) {
      return
    }
    onChangeIsEditing?.(true)
  }

  const handleSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()
    onChangeIsEditing?.(false)
  }

  return (
    <SettingsFieldWithLabel label={label} labelIcon={labelIcon}>
      {isEditing ? (
        <Row onSubmit={handleSubmitForm} component="form" align="center" data-testid={`settingsfield-${label}`}>
          <Col xs="content">{children}</Col>
        </Row>
      ) : (
        <Row align="center" nogutter data-testid={`settingsfield-${label}`}>
          <Col xs="content">
            <H3 disabled={disabled}>{isSecret ? "*********" : value}</H3>
          </Col>
          <Col>
            {icon && (
              <EditButtonStyle
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={handleClickEditIcon}
                style={{
                  marginLeft: "1rem",
                  cursor: disabled ? "auto" : "pointer",
                  color: disabled ? theme.colors.greyDisabled : undefined,
                }}
              >
                {icon}
              </EditButtonStyle>
            )}
          </Col>
        </Row>
      )}
    </SettingsFieldWithLabel>
  )
}

export default EditableField
