import * as React from "react"
import { theme } from "../../theme"
import { SignalStrengthWithZero } from "../../../types"

interface SignalStrengthBarsProps {
  strength: SignalStrengthWithZero
}

const SignalStrengthBars: React.FunctionComponent<SignalStrengthBarsProps> = ({ strength }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="106" height="100" viewBox="0 0 106 100" fill="none">
      <rect
        x="4"
        y="59"
        width="20"
        height="25"
        rx="3"
        fill={strength > 0 ? "url(#paint0_linear_first_bar)" : theme.colors.disabledBar}
      />
      <rect
        x="30"
        y="40"
        width="20"
        height="44"
        rx="3"
        fill={strength > 1 ? "url(#paint1_linear_second_bar)" : theme.colors.disabledBar}
      />
      <rect
        x="56"
        y="20"
        width="20"
        height="64"
        rx="3"
        fill={strength > 2 ? "url(#paint2_linear_third_bar)" : theme.colors.disabledBar}
      />
      <rect
        x="82"
        y="6"
        width="20"
        height="78"
        rx="3"
        fill={strength > 3 ? "url(#paint3_linear_fourth_bar)" : theme.colors.disabledBar}
      />
      <defs>
        <linearGradient
          id="paint0_linear_first_bar"
          x1="-3"
          y1="82.0469"
          x2="-5.0285"
          y2="69.3969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81A6C8" />
          <stop offset="1" stopColor="#97D1D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_second_bar"
          x1="23"
          y1="80.5625"
          x2="17.0304"
          y2="59.4109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81A6C8" />
          <stop offset="1" stopColor="#97D1D9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_third_bar"
          x1="49"
          y1="79"
          x2="37.3307"
          y2="50.5738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81A6C8" />
          <stop offset="1" stopColor="#97D1D9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_fourth_bar"
          x1="75"
          y1="77.9062"
          x2="58.8009"
          y2="45.5282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81A6C8" />
          <stop offset="1" stopColor="#97D1D9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignalStrengthBars
