import { useState, useCallback } from "react"

type Dimension = "height" | "width"

export const useMeasure = (dimension: Dimension): [(node: HTMLDivElement) => void, number] => {
  const [size, setSize] = useState<number>(0)

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setSize(node.getBoundingClientRect()[dimension])
    }
  }, [])

  return [ref, size]
}
