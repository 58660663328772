import * as React from "react"
import styled from "styled-components"
import { LogoutIcon } from "../icons/LogoutIcon"
import H3 from "../typography/H3"

interface ButtonProps {
  color?: string
  onClick?: () => void
}

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
  background-color: transparent;
  border: none;
`

const LogoutButton: React.FunctionComponent<ButtonProps> = ({ color, onClick }) => {
  return (
    <ButtonContainer onClick={onClick}>
      <LogoutIcon color={color} />
      <H3 color={color}>Logout</H3>
    </ButtonContainer>
  )
}

export default LogoutButton
