import * as React from "react"
import styled from "styled-components"
import QuestionMarkIcon from "./QuestionMarkIcon"

interface IconContainerProps extends React.PropsWithChildren {
  onClickHelp?: (e: React.MouseEvent) => void
}

const IconBackground = styled.div`
  width: 1.5rem;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.greyDark};
  cursor: ${(props) => (props.onClick !== undefined ? "pointer" : "initial")};
`

const AbsoluteQuestionMarkContainer = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  cursor: pointer;
`
const RelativeContainer = styled.div`
  position: relative;
  display: inline-flex;
`

const IconContainer: React.FunctionComponent<IconContainerProps> = ({ onClickHelp, children }) => {
  return (
    <RelativeContainer>
      <IconBackground onClick={onClickHelp}>{children}</IconBackground>
      {onClickHelp && (
        <AbsoluteQuestionMarkContainer>
          <div onClick={onClickHelp}>
            <QuestionMarkIcon />
          </div>
        </AbsoluteQuestionMarkContainer>
      )}
    </RelativeContainer>
  )
}

export default IconContainer
