import * as React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import BodyText from "../atoms/typography/BodyText"
import H3 from "../atoms/typography/H3"
import { useLocation } from "react-router-dom"
import { pages } from "../../utils/pages"
import LogoutButton from "../atoms/buttons/LogoutButton"
import { theme } from "../theme"
import { Breakpoints } from "../../utils/design-helpers"
import { useLogin } from "../../auth/LoginProvider"

interface VerticalNavbarProps {
  top: number
  isLoggedIn: boolean
  openNavbar: boolean
}

interface NavLinkH3Props {
  selected?: boolean
}

const ModalContainer = styled.div<{ top: number; openNavbar: boolean }>`
  position: sticky;
  top: ${(props) => props.top}px;
  height: calc(100vh - ${(props) => props.top}px);

  display: flex;
  width: 242px;
  padding-block: 2rem;
  padding-right: 0;
  box-sizing: border-box;
  z-index: 4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.greyDarker};

  @media (max-width: ${Breakpoints.SM}px) {
    display: ${({ openNavbar }) => (openNavbar ? "flex" : "none")};
    position: fixed;
    right: 0;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  width: 100%;
  height: 100%;
`

const SubLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const NavLink = styled(Link)`
  text-decoration: none;
  padding-left: 1rem;
  padding-block: 0.5rem;
  align-items: center;
  align-self: stretch;
  display: flex;
`
const SubLink = styled(NavLink)`
  display: flex;
  margin-left: 1.5rem;
  padding-left: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;

  &[data-active="true"] {
    background: #767676;
    border-left: solid 2px ${(props) => props.theme.gradients.blueBorder};
  }

  &:hover:not([data-active="true"]) {
    background: ${(props) => props.theme.colors.greyDark};
  }
`
const LogoutButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`
const NavLinkH3 = styled(H3).attrs<NavLinkH3Props>(({ selected }) => ({
  selected: selected || false,
}))<NavLinkH3Props>`
  color: white;
  font-weight: ${({ selected }) => (selected ? "bold" : "semibold")};
`

interface PageLinksProps {
  page: (typeof pages)[keyof typeof pages]
}

const PageLinks: React.FunctionComponent<PageLinksProps> = ({ page: { name, path, sections } }) => {
  const { pathname: currentPath, hash } = useLocation()

  const isActiveLink = currentPath === path
  const isIndexPage = hash.length === 0
  const isActiveSublink = (path: string, index: number) => path === hash || (index === 0 && isIndexPage)

  return (
    <>
      <NavLink to={path}>
        <NavLinkH3 selected={isActiveLink}>{name}</NavLinkH3>
      </NavLink>
      {isActiveLink && (
        <SubLinksContainer>
          {sections.map((section, i) => (
            <SubLink data-active={isActiveSublink(section.path, i)} to={section.path} key={section.path}>
              <BodyText color="white">{section.name}</BodyText>
            </SubLink>
          ))}
        </SubLinksContainer>
      )}
    </>
  )
}

const VerticalNavbar: React.ForwardRefRenderFunction<HTMLDivElement, VerticalNavbarProps> = (
  { isLoggedIn, top, openNavbar },
  ref,
) => {
  const location = useLocation()
  const { logout } = useLogin()

  React.useEffect(() => {
    if (location.hash.length === 0) {
      window.scrollTo(0, 0)
      return
    }
    const element = document.querySelector(location.hash)
    element?.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [location])

  return !isLoggedIn ? (
    <></>
  ) : (
    <ModalContainer top={top} ref={ref} openNavbar={openNavbar} data-testid="navbar">
      <ModalContent>
        {Object.values(pages).map((page) => (
          <PageLinks page={page} key={page.path} />
        ))}
      </ModalContent>
      <LogoutButtonContainer>
        <LogoutButton onClick={logout} color={theme.colors.white} />
      </LogoutButtonContainer>
    </ModalContainer>
  )
}

export default React.forwardRef(VerticalNavbar)
