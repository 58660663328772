import styled from "styled-components"

const Card = styled.div<{ noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? 0 : props.theme.spacings.extreme)};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 4px rgba(119, 119, 119, 0.25);
  border-radius: ${(props) => props.theme.borders.largeRadius};
  width: 100%;
  min-width: 349px;
`

export default Card
