import React from "react"

export const SatcubeLogo: React.FunctionComponent = () => {
  return (
    <svg width="165" height="27" viewBox="0 0 165 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M150.448 26.1371C150.206 26.1371 150.03 25.9392 150.03 25.6974V1.60469C150.03 1.36288 150.228 1.16504 150.448 1.16504H164.583C164.824 1.16504 165 1.36288 165 1.60469V5.38567C165 5.49558 164.956 5.62747 164.868 5.69342C164.78 5.78135 164.693 5.82531 164.561 5.82531C164.561 5.82531 164.209 5.82531 164.165 5.82531H155.636V11.189H163.242C163.308 11.189 163.462 11.189 163.505 11.189H163.659C163.769 11.189 163.879 11.233 163.945 11.3209C164.033 11.4088 164.077 11.5188 164.077 11.6287V14.904C164.077 14.948 164.077 15.0359 164.077 15.0799V15.2118C164.077 15.3217 164.033 15.4536 163.945 15.5196C163.857 15.6075 163.769 15.6515 163.637 15.6515H163.418C163.352 15.6515 163.264 15.6515 163.22 15.6515H155.592L155.614 21.4328H164.143C164.209 21.4328 164.407 21.4328 164.407 21.4328H164.539C164.78 21.4328 164.956 21.6307 164.956 21.8725V25.6974C164.956 25.9392 164.758 26.1371 164.539 26.1371H150.448Z"
        fill="white"
      />
      <path
        d="M129.499 26.1371C129.257 26.1371 129.081 25.9392 129.081 25.6974V1.60469C129.081 1.36288 129.279 1.16504 129.499 1.16504H139.061H139.083C141.413 1.31892 143.194 1.95641 144.359 3.09949C145.524 4.24258 146.117 5.91324 146.117 8.06752C146.117 9.56232 145.612 10.9912 144.711 12.0903C144.403 12.464 144.051 12.7937 143.677 13.0575C144.337 13.3653 144.886 13.761 145.348 14.2885C146.315 15.3877 146.843 17.0144 146.953 19.1027V19.1247C146.953 21.191 146.183 22.9716 144.755 24.2905C143.414 25.4996 141.633 26.159 139.699 26.159H129.499V26.1371ZM138.709 21.5867C139.501 21.5867 140.16 21.257 140.6 20.5975C140.996 20.0479 141.215 19.3005 141.215 18.5092C141.215 17.7178 140.952 16.9484 140.512 16.3989C140.182 16.0032 139.611 15.5415 138.709 15.5415H134.84V21.5647H138.709V21.5867ZM138.336 11.0791C139.171 11.0791 139.699 10.6395 139.984 10.2877C140.358 9.80413 140.6 9.16664 140.6 8.55113C140.6 7.14426 139.83 5.69342 138.336 5.69342H134.84V11.0791H138.336Z"
        fill="white"
      />
      <path
        d="M115.759 26.0711C112.835 26.0491 110.439 25.2797 108.791 23.8289C107.318 22.5539 106.526 20.7953 106.526 18.8829V1.60469C106.526 1.36288 106.724 1.16504 106.944 1.16504H111.736C111.846 1.16504 111.956 1.20901 112.044 1.29693C112.132 1.38486 112.176 1.49478 112.176 1.60469V1.73658C112.176 1.78055 112.176 1.86848 112.176 1.91244V18.8829C112.198 19.7182 112.616 20.3557 113.363 20.7953C114 21.1471 114.858 21.3449 115.803 21.3449C117.166 21.3449 119.166 20.6854 119.166 18.9049V1.93442C119.166 1.93442 119.166 1.78055 119.166 1.7146V1.62667C119.166 1.51676 119.21 1.38487 119.276 1.31892C119.342 1.25297 119.474 1.18702 119.584 1.18702H124.398C124.64 1.18702 124.816 1.38486 124.816 1.62667V18.8829C124.816 20.7294 124.024 22.466 122.596 23.7849C120.991 25.2578 118.749 26.0491 116.089 26.0711H115.913H115.759Z"
        fill="white"
      />
      <path
        d="M94.3699 26.0711C92.0618 26.0271 90.0614 25.3457 88.5666 24.0707C87.0938 22.8177 86.1925 21.1031 85.9727 19.0807C85.9727 19.0587 85.9727 19.0587 85.9727 19.0367V8.22138C85.9727 8.1994 85.9727 8.1994 85.9727 8.17742C86.1925 6.17702 87.0938 4.44041 88.5666 3.18741C90.1054 1.86847 92.1937 1.18701 94.5898 1.18701C94.6997 1.18701 94.8316 1.18701 94.9635 1.18701C97.3156 1.18701 99.338 1.86847 100.811 3.14345C102.152 4.3305 103.009 5.9572 103.273 7.89165C103.273 7.9576 103.295 8.04552 103.295 8.15544V8.24337C103.295 8.37526 103.273 8.48517 103.185 8.5731C103.097 8.66103 102.987 8.705 102.877 8.72698H102.745C102.723 8.72698 102.525 8.72698 102.437 8.72698H98.7664C98.7225 8.72698 98.6565 8.72698 98.6125 8.72698H98.4367C98.2388 8.72698 98.063 8.59509 98.019 8.39724L97.997 8.3313C97.997 8.3313 97.9531 8.17742 97.9531 8.11147C97.7552 7.03433 96.9858 5.73737 94.5898 5.73737C92.9411 5.73737 91.6661 6.74856 91.4463 8.24337V18.9928C91.6661 20.4876 92.9411 21.4988 94.5898 21.4988C97.0078 21.4988 97.7552 20.2018 97.9531 19.1247C97.9531 19.0807 97.9751 19.0147 97.9751 18.9708L97.997 18.8609C98.041 18.641 98.2169 18.4872 98.4147 18.4872H98.7664H102.437C102.525 18.4872 102.723 18.4872 102.723 18.4872H102.855C102.965 18.4872 103.097 18.5311 103.163 18.6191C103.251 18.707 103.273 18.8389 103.273 18.9488V19.0148C103.273 19.0587 103.251 19.2346 103.251 19.3005C102.987 21.235 102.13 22.8617 100.789 24.0487C99.316 25.3457 97.2936 26.0271 94.9415 26.0271C94.8316 26.0271 94.6997 26.0271 94.5898 26.0271H94.4139C94.3919 26.0711 94.3919 26.0711 94.3699 26.0711Z"
        fill="white"
      />
      <path
        d="M72.4981 26.0711C72.2783 26.0711 72.0804 25.8733 72.0804 25.6315V25.5435C72.0804 25.5435 72.0804 25.3677 72.0804 25.3017L72.0585 5.86927H66.1892C66.0792 5.86927 65.9693 5.8253 65.8814 5.73737C65.7935 5.64944 65.7495 5.53953 65.7495 5.42962C65.7495 5.27574 65.7495 5.14385 65.7495 5.12186V1.93441C65.7495 1.91243 65.7495 1.75855 65.7495 1.62666C65.7495 1.51675 65.7935 1.38485 65.8814 1.31891C65.9693 1.23098 66.0792 1.18701 66.1892 1.18701H83.7751C84.0169 1.18701 84.1928 1.38485 84.1928 1.62666V5.4516C84.1928 5.56151 84.1488 5.69341 84.0609 5.75935C83.9729 5.84728 83.863 5.89125 83.7531 5.89125C83.7531 5.89125 83.4014 5.89125 83.3354 5.89125H77.7959V25.3237C77.7959 25.3677 77.7959 25.6315 77.7959 25.6315C77.7959 25.7414 77.7519 25.8513 77.664 25.9392C77.576 26.0271 77.4661 26.0711 77.3562 26.0711H72.4981Z"
        fill="white"
      />
      <path
        d="M46.405 26.137C46.2731 26.137 46.1412 26.0711 46.0752 25.9612C45.9873 25.8513 45.9653 25.6974 46.0093 25.5655L46.1192 25.1698L53.2195 1.78053C53.2415 1.71458 53.2855 1.58268 53.2855 1.58268L53.3075 1.51674C53.3734 1.34088 53.5273 1.20898 53.7031 1.20898H59.6824C59.8582 1.20898 60.0341 1.34088 60.078 1.51674L60.166 1.75854L67.3762 25.1698C67.3982 25.2138 67.4202 25.3237 67.4421 25.3896L67.4861 25.5435C67.5301 25.6754 67.5081 25.8293 67.4202 25.9392C67.3322 26.0491 67.2223 26.1151 67.0684 26.1151H61.9246C61.7487 26.1151 61.5728 25.9832 61.5289 25.8073L61.5069 25.7414C61.4849 25.6754 61.4629 25.6095 61.4409 25.5435L59.9681 20.5315C59.9242 20.5095 58.6931 19.8721 56.6268 19.8721C54.5604 19.8721 53.3294 20.5095 53.2635 20.5315L51.7687 25.5435L51.7027 25.8073C51.6588 26.0051 51.4829 26.137 51.2851 26.137H46.405ZM54.6264 15.8713C54.6264 15.8713 55.8574 15.7174 56.6488 15.7174H56.6708C57.4841 15.7174 58.6931 15.8713 58.7591 15.8713L56.6708 7.95759L54.6264 15.8713Z"
        fill="white"
      />
      <path
        d="M36.2928 26.0711C33.8308 26.0272 31.7424 25.3897 30.4235 24.2246C28.4231 22.488 28.0054 20.2238 27.9395 19.3445C27.9395 19.2566 27.9175 19.0807 27.9175 19.0807V19.0148C27.9175 18.9049 27.9614 18.773 28.0274 18.685C28.0933 18.5971 28.2252 18.5531 28.3351 18.5531H32.314C32.3359 18.5531 32.4898 18.5531 32.6437 18.5531C32.8415 18.5531 32.9954 18.685 33.0614 18.8829L33.0833 18.9488C33.0833 18.9928 33.1053 19.0587 33.1273 19.1027C33.3032 19.6523 33.8308 20.9932 35.2816 21.4988C35.3695 21.5208 35.9411 21.6746 36.7104 21.6746C37.282 21.6746 38.0734 21.5867 38.6669 21.191C39.5901 20.5755 39.6561 19.5204 39.6341 19.1027V19.0807C39.6121 18.4872 39.3044 17.6299 38.0734 16.9044C36.8423 16.201 35.6773 15.6295 34.6661 15.1239L34.6441 15.1019C32.5778 14.0907 30.9291 13.2993 29.8739 12.0024C29.4123 11.4308 28.511 10.3317 28.4451 8.17743V8.15545V8.0895C28.489 5.31972 29.742 3.23139 31.9403 2.17623C33.2812 1.53874 35.0618 1.16504 36.7764 1.16504C39.1505 1.16504 41.2168 1.82451 42.6017 3.03354C44.5142 4.68223 44.9319 6.79254 45.0198 7.89166C45.0198 7.95761 45.0198 8.11148 45.0418 8.15545V8.2214C45.0418 8.35329 44.9978 8.4632 44.9319 8.55113C44.8659 8.63906 44.734 8.68303 44.6241 8.68303H44.4263C44.3603 8.68303 44.2504 8.68303 44.2065 8.68303H40.2276C40.0298 8.68303 39.8539 8.52915 39.81 8.33131L39.788 8.19941C39.5462 7.01236 38.8867 6.22099 37.8535 5.89126C37.3699 5.7154 36.8863 5.62747 36.4467 5.62747C35.8751 5.62747 35.3695 5.75937 34.9738 6.04514C33.8967 6.77056 33.9846 7.97959 34.0066 8.11148C34.0286 8.44122 34.2484 8.85889 34.6001 9.25457C34.8859 9.5843 35.3695 10.0459 36.1609 10.3977C36.5786 10.5955 36.9962 10.7714 37.3699 10.9472C39.788 12.0463 41.5466 12.8377 43.2392 14.3765C43.9866 15.0579 45.2176 16.5527 45.2176 18.9928V19.0148V19.0807V19.1027C45.0857 22.6419 43.2392 24.2466 41.7224 24.972C40.3595 25.6315 38.469 26.0052 36.5566 26.0052L36.2928 26.0711Z"
        fill="white"
      />
      <path
        d="M10.6834 26.1373C10.5076 26.1373 10.3537 26.0054 10.3317 25.8076C9.91406 20.5538 5.60551 16.4651 0.351709 16.2892C0.153867 16.2892 0.0219727 16.1353 0.0219727 15.9375V9.71645C0.0219727 9.62853 0.0659375 9.54059 0.131885 9.47465C0.197832 9.4087 0.285761 9.38672 0.373691 9.38672C4.77017 9.47465 8.90287 11.2113 12.0464 14.2668C15.1898 17.3224 17.0364 21.4111 17.2562 25.7636C17.2562 25.8515 17.2342 25.9615 17.1683 26.0274C17.1023 26.0934 17.0144 26.1373 16.9264 26.1373H10.6834Z"
        fill="white"
      />
      <path
        d="M19.8941 26.1371C19.6962 26.1371 19.5424 25.9832 19.5424 25.8074C19.3885 20.7514 17.3221 15.9812 13.739 12.3981C10.1559 8.81495 5.38569 6.7486 0.329736 6.59472C0.131894 6.59472 0 6.44085 0 6.243V0.329736C0 0.241807 0.0439648 0.153876 0.109912 0.0879283C0.175859 0.0219811 0.263789 0 0.351718 0C7.16627 0.153877 13.5851 2.90168 18.4213 7.73781C23.2574 12.5739 26.0052 18.9928 26.1591 25.8074C26.1591 25.8953 26.1371 25.9832 26.0711 26.0492C26.0052 26.1151 25.9173 26.1591 25.8293 26.1591L19.8941 26.1371Z"
        fill="white"
      />
      <path
        d="M0.351718 26.137C0.153877 26.137 0 25.9832 0 25.7853V19.1686C0 19.0807 0.0439648 18.9708 0.109912 18.9268C0.175859 18.8609 0.263789 18.8389 0.351718 18.8389H0.3737C4.17666 19.0147 7.36411 22.0043 7.80376 25.7633C7.82574 25.8732 7.78178 25.9612 7.71583 26.0271C7.64988 26.0931 7.56195 26.137 7.45204 26.137H0.351718Z"
        fill="white"
      />
      <path
        d="M24.0927 11.2992C24.0048 11.2992 23.8949 11.2552 23.8509 11.1673L23.5871 10.7496C21.4548 7.51818 18.6191 4.68245 15.3877 2.55015L14.97 2.28637C14.8601 2.22042 14.8161 2.08852 14.8601 1.95663C14.9041 1.82473 15.014 1.75879 15.1459 1.75879H24.1147C24.2686 1.75879 24.4005 1.89068 24.4005 2.04456V11.0134C24.4005 11.1453 24.3125 11.2552 24.2026 11.2992C24.1587 11.2992 24.1147 11.2992 24.0927 11.2992Z"
        fill="white"
      />
    </svg>
  )
}
