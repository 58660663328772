import * as React from "react"
import { Col, Row } from "react-grid-system"
import { Spacer } from "../../atoms/spacers"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import MenuBookIcon from "../../atoms/icons/MenuBookIcon"
import DownloadIcon from "../../atoms/icons/DownloadIcon"
import GenericCardRow from "../../atoms/card/SettingsField"
import ButtonTextSmall from "../../atoms/typography/ButtonTextSmall"

export interface GuidesAndManualsCardProps {
  files: { fileName: string; url: string }[]
}

const GuidesAndManualsCard: React.FunctionComponent<GuidesAndManualsCardProps> = ({ files }) => {
  return (
    <SettingsOverviewCard title="Guides and manuals" icon={<MenuBookIcon />}>
      {files.map(({ fileName, url }) => (
        <GenericCardRow key={fileName}>
          <a href={url} style={{ textDecoration: "none" }}>
            <Col>
              <Row>
                <DownloadIcon />
                <Spacer spacing={1} />
                <ButtonTextSmall>{fileName}</ButtonTextSmall>
              </Row>
            </Col>
          </a>
        </GenericCardRow>
      ))}
    </SettingsOverviewCard>
  )
}

export default GuidesAndManualsCard
