// Top level error for all Edge related errors
export class EdgeError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export class ProtoMissingFieldError extends EdgeError {
  constructor(protoMessage: string, field: string) {
    super(`protobuf message ${protoMessage} missing field ${field}`)
  }
}

export class MissingMetadataError extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}

export class UnexpectedMetricValue extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}
