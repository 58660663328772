import * as React from "react"

interface IconProps {
  on: boolean
}

const TxIcon: React.FunctionComponent<IconProps> = ({ on }) => {
  return on ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Tx/on">
        <g id="Union">
          <path
            d="M6.57464 6.57324L8.46797 8.46658C6.53464 10.3866 5.33464 13.0532 5.33464 15.9999C5.33464 18.9466 6.53464 21.6132 8.46797 23.5332L6.57464 25.4266C4.1613 23.0132 2.66797 19.6799 2.66797 15.9999C2.66797 12.3199 4.1613 8.98658 6.57464 6.57324Z"
            fill="white"
          />
          <path
            d="M26.668 15.9999C26.668 18.9466 25.468 21.6132 23.5346 23.5332L25.428 25.4266C27.8413 23.0132 29.3346 19.6799 29.3346 15.9999C29.3346 12.3199 27.8413 8.98658 25.428 6.57324L23.5346 8.46658C25.468 10.3866 26.668 13.0532 26.668 15.9999Z"
            fill="white"
          />
          <path
            d="M8.0013 15.9999C8.0013 18.2132 8.89464 20.2132 10.348 21.6532L12.228 19.7732C11.268 18.7999 10.668 17.4666 10.668 15.9999C10.668 14.5332 11.268 13.1999 12.2413 12.2399L10.348 10.3466C8.89464 11.7866 8.0013 13.7866 8.0013 15.9999Z"
            fill="white"
          />
          <path
            d="M24.0013 15.9999C24.0013 18.2132 23.108 20.2132 21.6546 21.6532L19.7746 19.7732C20.7346 18.7999 21.3346 17.4666 21.3346 15.9999C21.3346 14.5332 20.7346 13.1999 19.7613 12.2399L21.6546 10.3466C23.108 11.7866 24.0013 13.7866 24.0013 15.9999Z"
            fill="white"
          />
          <path
            d="M17.3346 17.9151C17.9391 17.4935 18.3346 16.7929 18.3346 16C18.3346 14.7113 17.29 13.6667 16.0013 13.6667C14.7126 13.6667 13.668 14.7113 13.668 16C13.668 16.7929 14.0635 17.4935 14.668 17.9151V28H17.3346V17.9151Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Tx/off">
        <g id="Union">
          <path
            d="M1.91992 5.67663L3.80554 3.79102L28.2382 28.2237L26.3526 30.1093L17.3346 21.0913V28.0007H14.668V18.4247L14.2542 18.0109C14.1605 17.9293 14.0724 17.8413 13.9908 17.7475L10.8533 14.61C10.7324 15.0535 10.668 15.5198 10.668 16.0004C10.668 17.4671 11.268 18.8004 12.228 19.7738L10.348 21.6538C8.89464 20.2138 8.0013 18.2138 8.0013 16.0004C8.0013 14.76 8.2819 13.5865 8.78441 12.5411L6.82076 10.5775C5.87732 12.1639 5.33464 14.0188 5.33464 16.0004C5.33464 18.9471 6.53464 21.6138 8.46797 23.5338L6.57464 25.4271C4.1613 23.0138 2.66797 19.6804 2.66797 16.0004C2.66797 13.2806 3.48371 10.7501 4.88373 8.64044L1.91992 5.67663Z"
            fill="white"
          />
          <path
            d="M21.1583 17.3597L23.2312 19.4326C23.7255 18.394 24.0013 17.2302 24.0013 16.0004C24.0013 13.7871 23.108 11.7871 21.6546 10.3471L19.7613 12.2404C20.7346 13.2004 21.3346 14.5338 21.3346 16.0004C21.3346 16.4696 21.2732 16.9252 21.1583 17.3597Z"
            fill="white"
          />
          <path
            d="M27.1349 23.3363L25.1968 21.3982C26.131 19.8173 26.668 17.9715 26.668 16.0004C26.668 13.0538 25.468 10.3871 23.5346 8.4671L25.428 6.57377C27.8413 8.9871 29.3346 12.3204 29.3346 16.0004C29.3346 18.7099 28.5251 21.2315 27.1349 23.3363Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}

export default TxIcon
