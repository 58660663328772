import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  color?: string
  open?: boolean
}

export const ArrowDropDownIcon: React.FunctionComponent<IconProps> = ({ open, color = theme.colors.greyDark }) => {
  return open ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 14.5L12 9.5L7 14.5L17 14.5Z" fill={color} />
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 9.5L12 14.5L17 9.5L7 9.5Z" fill={color} />
    </svg>
  )
}
