import * as React from "react"
import { Row, Col } from "react-grid-system"
import {
  CoordinateType,
  CoordinateTypeMap,
  coordinateToString,
  ITEMS_LATITUDE,
  ITEMS_LONGITUDE,
  isValidCoordinate,
  Coordinate,
} from "../../../utils/coordinates"
import { capitalizeFirstLetter } from "../../../utils/formatters"
import EditableField from "./EditableSettingsField"
import { Spacer } from "../../atoms/spacers"
import Dropdown, { DropdownItem } from "./Dropdown"
import InputBox from "./InputBox"

interface EditableCoordinateFieldProps<T extends CoordinateType> {
  type: T
  onChange?: (value: CoordinateTypeMap[T]) => void
  value: CoordinateTypeMap[T]
}

type CoordinateItem<T extends CoordinateType> = DropdownItem<CoordinateTypeMap[T]["direction"]>[]

const getItems = <T extends CoordinateType>(type: T): CoordinateItem<T> => {
  return type === "latitude" ? ITEMS_LATITUDE : ITEMS_LONGITUDE
}

const getSelectedDirectionItem = <T extends CoordinateType>(
  items: CoordinateItem<T>,
  direction: Coordinate["direction"],
) => {
  return items.find((item) => item.id === direction) ?? items[0]
}

const EditableCoordinateField = <T extends CoordinateType>({
  value: controlledValue,
  onChange,
  type,
}: EditableCoordinateFieldProps<T>) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const dropdownRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing, inputRef.current])

  const isValid = isValidCoordinate(controlledValue)
  const formattedCoordinate = coordinateToString(controlledValue)

  const label = capitalizeFirstLetter(type)
  const items = getItems(type)
  const selectedDirectionItem = getSelectedDirectionItem(items, controlledValue.direction)

  const handleOnChangeDirection = ({ id: changedDirection }: DropdownItem<CoordinateTypeMap[T]["direction"]>) => {
    onChange?.({ ...controlledValue, direction: changedDirection })
  }

  const handleOnChangeDegrees: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const changedDegrees = parseFloat(e.target.value)
    onChange?.({ ...controlledValue, degrees: isNaN(changedDegrees) ? 0 : changedDegrees })
  }

  const handleChangeIsEditing = (startEditing: boolean) => {
    if (!startEditing && isValid) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }

    return isValid
  }

  const handleOnBlur: React.FocusEventHandler = (e) => {
    console.log({ dropdown: dropdownRef.current, related: e.relatedTarget })
    if (
      e.relatedTarget != inputRef.current &&
      e.relatedTarget != dropdownRef.current &&
      !dropdownRef.current?.contains(e.relatedTarget)
    ) {
      setIsEditing(!isValid)
    }
  }

  return (
    <EditableField
      isEditing={isEditing}
      onChangeIsEditing={handleChangeIsEditing}
      value={formattedCoordinate}
      label={label}
    >
      <Row align="center" nogutter>
        <Col>
          <InputBox
            formNoValidate={true}
            step="any"
            type="number"
            onBlur={handleOnBlur}
            value={controlledValue.degrees}
            onChange={handleOnChangeDegrees}
            error={!isValid && "Invalid input"}
            ref={inputRef}
          />
        </Col>
        <Spacer spacing={1} />
        <Col xs="content">
          <Dropdown
            ref={dropdownRef}
            items={items}
            onBlur={handleOnBlur}
            onClickItem={handleOnChangeDirection}
            selectedItem={selectedDirectionItem}
          />
        </Col>
      </Row>
    </EditableField>
  )
}

export default EditableCoordinateField
