import * as React from "react"
import styled from "styled-components"
import { Row } from "react-grid-system"
import BodyText from "../../atoms/typography/BodyText"
import { theme } from "../../theme"

interface TooltipRowProps {
  visible: boolean
  tooltip: string
}

const TooltipRowContainer = styled(Row)<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "flex" : "none")} !important;
  position: relative;
  top: 0.6rem;
  padding: 0.75rem;
  margin: 0 !important;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.greyDark};

  ::before,
  ::after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -7px;
    left: 1.75rem;
    transform: translate(-50%);
  }

  ::before {
    border-bottom: 15px solid ${(props) => props.theme.colors.greyDark};
    margin-top: -1px;
  }
`

const TooltipRow: React.FunctionComponent<TooltipRowProps> = ({ visible, tooltip }) => {
  return (
    <TooltipRowContainer data-testid="tooltip" visible={visible}>
      <BodyText color={theme.colors.white}>{tooltip}</BodyText>
    </TooltipRowContainer>
  )
}

export default TooltipRow
