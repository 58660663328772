import * as React from "react"
import GenericConfirmationModal from "../../molecules/modals/GenericConfirmationModal"

interface FactoryResetModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const FactoryResetModal: React.FunctionComponent<FactoryResetModalProps> = ({ open, onClose, onConfirm }) => {
  return (
    <GenericConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={"Revert to factory settings"}
      message={"Restores all configuration to the default values. Including passwords and login details."}
      confirmText={"Confirm"}
    />
  )
}

export default FactoryResetModal
