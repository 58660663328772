import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { HorizontalSpacer } from "../../atoms/spacers"
import BodyTextLarge from "../../atoms/typography/BodyTextLarge"
import { Row, Col } from "react-grid-system"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import styled from "styled-components"

interface GenericConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  confirmText: string
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;

  & > * {
    margin-top: 0.5rem;
  }
`

const GenericConfirmationModal: React.FunctionComponent<GenericConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  confirmText,
  message,
}) => {
  return (
    <AnimatePresence mode="wait">
      {open && (
        <Modal title={title} onClose={onClose} closeIcon>
          <HorizontalSpacer spacing={2} />
          <Row style={{ margin: "0px !important" }}>
            <Col>
              <BodyTextLarge>{message}</BodyTextLarge>
            </Col>
          </Row>
          <HorizontalSpacer spacing={2} />
          <ButtonContainer>
            <PrimaryButton variant="light" onClick={onClose} title="Cancel" />
            <PrimaryButton onClick={onConfirm} title={confirmText} />
          </ButtonContainer>
        </Modal>
      )}
    </AnimatePresence>
  )
}

export default GenericConfirmationModal
