export const isAvailableUpdateNewer = (latestOsVersionAvailable: string, osVersion: string) => {
  const [latestMajor, latestMinor, latestPatch] = latestOsVersionAvailable.split(".").map(Number)
  const [osMajor, osMinor, osPatch] = osVersion.split(".").map(Number)

  if (latestMajor > osMajor) return true
  if (latestMajor < osMajor) return false

  if (latestMinor > osMinor) return true
  if (latestMinor < osMinor) return false

  return latestPatch > osPatch
}
