import * as React from "react"
import { theme } from "../../theme"

interface ResetIconProps {
  color?: string
}

const ResetIcon: React.FunctionComponent<ResetIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6 13.2849C6 11.6349 6.67 10.1349 7.76 9.04491L6.34 7.62491C4.9 9.07491 4 11.0749 4 13.2849C4 17.3649 7.05 20.7249 11 21.2149V19.1949C8.17 18.7149 6 16.2549 6 13.2849ZM20 13.2849C20 8.86491 16.42 5.28491 12 5.28491C11.94 5.28491 11.88 5.29491 11.82 5.29491L12.91 4.20491L11.5 2.78491L8 6.28491L11.5 9.78491L12.91 8.37491L11.83 7.29491C11.89 7.29491 11.95 7.28491 12 7.28491C15.31 7.28491 18 9.97491 18 13.2849C18 16.2549 15.83 18.7149 13 19.1949V21.2149C16.95 20.7249 20 17.3649 20 13.2849Z"
        fill={color ? color : theme.colors.redDark}
      />
    </svg>
  )
}

export default ResetIcon
