import * as React from "react"
import { Col, Container, Row } from "react-grid-system"
import styled from "styled-components"
import { theme } from "../../components/theme"
import BodyTextMedium from "../atoms/typography/BodyTextMedium"
import { SatcubeLogo } from "../../images/SatcubeLogo"
import { MediaQuery } from "../../utils/design-helpers"
import { HorizontalSpacer } from "../atoms/spacers"
import { Link } from "react-router-dom"

const HeaderContainer = styled.div`
  background-color: ${(props) => props.theme.colors.greyDarkest};
  padding: 5rem;

  ${MediaQuery.MD} {
    padding: 2rem 1rem;
  }
`

const HomePageLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
`

const IconRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LinkWithoutDecoration = styled.a`
  color: ${(props) => props.theme.colors.greyDark};
  text-decoration: none;
`

const Footer: React.FunctionComponent = () => {
  return (
    <HeaderContainer>
      <Container>
        <Row>
          <Col sm={12} md={3} lg={4}>
            <BodyTextMedium weight="400" color={theme.colors.white}>
              Telephone
            </BodyTextMedium>
            <HorizontalSpacer spacing={0.5} />
            <BodyTextMedium weight="700" color={theme.colors.white}>
              +46 31 701 05 00
            </BodyTextMedium>
            <BodyTextMedium weight="700" color={theme.colors.white}>
              +1 888 6175 180 (US)
            </BodyTextMedium>
            <HorizontalSpacer spacing={2} />
          </Col>
          <Col sm={12} md={3} lg={4}>
            <BodyTextMedium weight="400" color={theme.colors.white}>
              Fleet management:
            </BodyTextMedium>
            <HorizontalSpacer spacing={0.5} />
            <HomePageLink to={"https://satcube.com"} target="_blank">
              <BodyTextMedium weight="700" color={theme.colors.white}>
                Satcube.com
              </BodyTextMedium>
            </HomePageLink>
            <HorizontalSpacer spacing={2} />
          </Col>
          <Col sm={12} md={3} lg={4}>
            <BodyTextMedium weight="400" color={theme.colors.white}>
              Mail:
            </BodyTextMedium>
            <HorizontalSpacer spacing={0.5} />
            <LinkWithoutDecoration href="mailto:support@satcube.com">
              <BodyTextMedium weight="700" color={theme.colors.white}>
                support@satcube.com
              </BodyTextMedium>
            </LinkWithoutDecoration>
            <LinkWithoutDecoration href="mailto:sales@satcube.com">
              <BodyTextMedium weight="700" color={theme.colors.white}>
                sales@satcube.com
              </BodyTextMedium>
            </LinkWithoutDecoration>
            <HorizontalSpacer spacing={2} />
          </Col>
        </Row>
        <HorizontalSpacer spacing={4} />
        <Row>
          <Col>
            <IconRowContainer>
              <SatcubeLogo />
            </IconRowContainer>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  )
}

export default Footer
