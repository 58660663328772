import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import BodyTextLarge from "../../atoms/typography/BodyTextLarge"
import SpinnerIcon from "../../atoms/icons/SpinnerIcon"

const SoftwareUpdateProgressModal: React.FunctionComponent = () => {
  const onClose = () => {
    // We do not allow to close the modal during the installation
  }

  return (
    <AnimatePresence mode="wait">
      <Modal title={"Installing software update"} leftIcon={<SpinnerIcon />} onClose={onClose} centerTitle={true}>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <BodyTextLarge textAlign="center">
              This may take a few minutes after which the terminal will restart. Make sure the terminal is connected to
              a power source for the duration of the installation.
            </BodyTextLarge>
          </Col>
        </Row>
      </Modal>
    </AnimatePresence>
  )
}

export default SoftwareUpdateProgressModal
