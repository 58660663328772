import { css } from "styled-components"

export interface BaseTextProps {
  textAlign?: string
  color?: string
  opacity?: string
  weight?: string
  disabled?: boolean
}

export const baseTextStyle = (defaultWeight: number) => css<BaseTextProps>`
  color: ${(props) => (props.disabled ? props.theme.colors.greyDisabled : props.color ?? props.theme.colors.greyDark)};
  opacity: ${(props) => props.opacity ?? "1"};
  text-align: ${(props) => props.textAlign ?? "left"};
  font-weight: ${(props) => defaultWeight ?? props.weight};
`
