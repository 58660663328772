import { DropdownItem } from "../components/molecules/inputs/Dropdown"

export type Latitude = {
  direction: "north" | "south"
  degrees: number
}

export type Longitude = {
  direction: "east" | "west"
  degrees: number
}

export type CoordinateTypeMap = {
  latitude: Latitude
  longitude: Longitude
}

export type Coordinate<T extends Coordinate = Longitude | Latitude> = Extract<Longitude | Latitude, T>

export type CoordinateType = keyof CoordinateTypeMap

export const DIRECTIONS = ["west", "east", "north", "south"]

export const ITEMS_LATITUDE: DropdownItem<Latitude["direction"]>[] = [
  { id: "north", title: "N" },
  { id: "south", title: "S" },
]

export const ITEMS_LONGITUDE: DropdownItem<Longitude["direction"]>[] = [
  { id: "east", title: "E" },
  { id: "west", title: "W" },
]

export function isValidCoordinate(coordinate: Coordinate) {
  const { degrees, direction } = coordinate

  if (degrees == undefined || direction == undefined) {
    return false
  }

  if (isNaN(degrees)) {
    return false
  }

  if (degrees < 0) {
    return false
  }

  if (coordinateType(coordinate) === "latitude") {
    return degrees <= 90
  } else {
    return degrees <= 180
  }
}

export function coordinateToString({ degrees, direction }: Coordinate) {
  return `${degrees} ${direction[0].toUpperCase()}`
}

export function coordinateType({ direction }: Coordinate): CoordinateType {
  if (["east", "west"].includes(direction)) {
    return "latitude"
  } else if (["north", "south"].includes(direction)) {
    return "longitude"
  } else {
    throw new Error(`Invalid coordinate type with direction ${direction}`)
  }
}
