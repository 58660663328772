import styled from "styled-components"
import { BaseTextProps, baseTextStyle } from "./types"

const BodyText = styled.div<BaseTextProps>`
  ${baseTextStyle(500)}
  font-family: "Karla", "Karla-fallback", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 23px;
`

export default BodyText
