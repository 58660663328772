import React, { createContext, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { setUser, clearUser, checkAuth, ackLogout } from "../redux/slices/userSlice"
import { EDGE_HTTP_LOGIN, EDGE_HTTP_LOGOUT } from "../edge/endpoints"

import * as protoAuth from "edge-proto/dist/edge/v1/auth"
import { useInterval } from "usehooks-ts"

type LoginResult = { result: "success" } | { result: "fail"; errorMessage: string }

interface LoginContextType {
  isLoggedIn: boolean
  loading: boolean
  login: (username: string, password: string) => Promise<LoginResult>
  logout: () => Promise<void>
}

const LoginContext = createContext<LoginContextType | undefined>(undefined)

export const useLogin = () => {
  const context = useContext(LoginContext)
  if (!context) {
    throw new Error("useLogin must be used within a LoginProvider")
  }
  return context
}

const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    if (user.loginState !== "logout_unack") {
      return
    }
    switch (user.logoutReason) {
      case "session_expired":
        alert("The login session has expired and you will need to login again to continue using the application")
        break
      case "api_unauthorized":
        alert("Connection to the device API was unexpectedly unauthenticated, please log in again")
        break
    }
    dispatch(ackLogout())
  }, [user.loginState])

  React.useEffect(() => {
    dispatch(checkAuth({ appStart: true }))
  }, [])

  useInterval(() => {
    dispatch(checkAuth({ appStart: false }))
    return
  }, 1000)

  React.useEffect(() => {
    setIsLoggedIn(!!user.username)
    setLoading(false)
  }, [user])

  async function login(username: string, password: string): Promise<LoginResult> {
    const loginCredentials = protoAuth.LoginCredentials.create({ username, password })
    const loginCredentialsBytes = protoAuth.LoginCredentials.encode(loginCredentials).finish()

    const error = (msg: string): LoginResult => {
      return { result: "fail", errorMessage: msg }
    }

    const response = await fetch(EDGE_HTTP_LOGIN, {
      method: "POST",
      credentials: "include",
      body: loginCredentialsBytes,
    }).catch((error) => {
      return error
    })
    if (response instanceof Error) {
      return error(`Failed to communicate with the device: ${response.message}`)
    }
    if (!response.ok) {
      return error("Invalid username or password")
    }
    const bodyBuffer = await response.arrayBuffer()
    const loginResponse = protoAuth.LoginResponse.decode(new Uint8Array(bodyBuffer))
    dispatch(
      setUser({
        username: loginResponse.username,
        sessionExpires: Date.now() + loginResponse.validityTimeSeconds * 1000,
      }),
    )
    return { result: "success" }
  }

  async function logout() {
    await fetch(EDGE_HTTP_LOGOUT, { method: "PUT", credentials: "include" }).catch((_err) => {
      console.log(_err)
    })
    // Nb: We don't care about response from logout, since we should logout on the front-end
    // regardless. For example, if the embedded device isn't in reach, we should still be
    // able to logout.
    dispatch(clearUser("user_logout"))
  }

  return <LoginContext.Provider value={{ isLoggedIn, loading, login, logout }}>{children}</LoginContext.Provider>
}

export default LoginProvider
