import * as React from "react"
import EditableTextField, { EditableTextFieldProps } from "./EditableTextField"
import HiddenIcon from "../../atoms/icons/HiddenIcon"

const EditablePasswordField: React.FunctionComponent<Omit<EditableTextFieldProps, "isSecret" | "icon">> = ({
  ...props
}) => {
  return <EditableTextField {...props} icon={<HiddenIcon />} isSecret={true} />
}

export default EditablePasswordField
