import styled from "styled-components"
import { BaseTextProps, baseTextStyle } from "./types"

const BodyTextLarge = styled.div<BaseTextProps>`
  ${baseTextStyle(500)}
  font-family: "Karla", "Karla-fallback", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
`

export default BodyTextLarge
